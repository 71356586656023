import styled from "styled-components";

const table = styled.div`
  background-color: #f4f4f4;
  overflow-x: auto;
  display: grid;
  width: calc(100vw - 32px);
`;

const theader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  min-height: 65px;
  padding: 26px 16px;
  font-size: 14px;
  align-items: stretch;
  justify-content: center;
  justify-items: center;
  border-bottom: 1px solid #d9d9d9;
`;
const tbody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 26px 16px;
  font-size: 15px;
  > div {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #ccc;
    }
  }
`;

const tr = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 3px;
  width: 100%;
  padding: 0;
  cursor: pointer;
  background-color: ${({ color }) => (color ? "#fff" : "none")};
`;

const th = styled.div`
  width: ${({ size }) => (size ? size + "px" : "117px")};
  height: 33px;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 16px;
  text-align: center;
  &:first-child {
    width: 48px;
    padding-left: 0.563rem;
  }
`;

const thTwoLine = styled.div`
  width: ${({ size }) => (size ? size + "px" : "117px")};

  height: 33px;
  display: flex;
  align-items: center;
  font-weight: 600;
  flex-direction: column;
  > div.headerTwoLinePercent {
    display: flex;
    align-items: center;
  }
`;

const td = styled.div`
  width: ${({ size }) => (size ? size + "px" : "117px")};

  height: 74px;
  display: flex;
  align-items: center;
  padding: 16px;

  font-size: 14px;
  color: var(--text-text-secondary, #525252);
  font-weight: 700;

  &:first-child {
    width: 48px;
  }
  .icons {
    .text-status-icon {
      display: block;
    }
    svg {
      color: #41a05f;
    }
  }
`;

const tdMultLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: ${({ color }) => (color ? "700" : "400")};

  .lineColor {
    color: #6f6f6f;
  }
  .disabled {
    color: #c6c6c6;
  }
`;

export { table, theader, tbody, tr, th, thTwoLine, td, tdMultLine };
