import axios from "./BaseService";

const API_URL =
  `${process.env.REACT_APP_API_URL}` ||
  "http://localhost:3001";

export async function saveManySalesChannels(token, channels) {
  const planUrl = `${API_URL}/api/saleschannels/many`;
  const headers = { authorization: token };
  const response = await axios.post(planUrl, channels, { headers });
  return response.data;
}
