import React, { useContext } from "react";
import { TextInput } from "@carbon/react";
import { CreatePlanContext } from "../../../../contexts/CreatePlanContext";

import * as S from "./TargetSection.style";

export default function TargetSection() {
  const { currentYear, periodTarget, setPeriodTarget, yearTarget } =
    useContext(CreatePlanContext);

  const formatNumberWithDots = (numberStr) => {
    const cleanedStr = numberStr.replace(/\D/g, "");

    let reversedStr = cleanedStr.split("").reverse().join("");
    let formattedReversedStr = reversedStr.replace(/(\d{3})(?=\d)/g, "$1.");
    return formattedReversedStr.split("").reverse().join("");
  };

  const handleChange = (e, setPeriodTarget) => {
    const formattedValue = formatNumberWithDots(e.target.value.substring(3));
    setPeriodTarget(formattedValue);
  };

  const formatThousands = (num) =>
    num
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return (
    <S.Container>
      <h4>Meta</h4>

      <div>
        <S.Label>Meta para este período</S.Label>
        <TextInput
          value={`R$ ${periodTarget}`}
          onChange={(e) => handleChange(e, setPeriodTarget)}
        />
      </div>

      <div>
        <S.Label>Meta para {currentYear}</S.Label>
        <TextInput
          value={`R$ ${formatThousands(yearTarget)}`}
          disabled
          style={{ border: "1px solid #d9d7d7" }}
        />
      </div>
    </S.Container>
  );
}
