import styled from 'styled-components';
import {
  Tile,
} from '@carbon/react';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f4;
  padding: 2rem;
`;

const StyledTile = styled(Tile)`
  padding: 2rem;
  max-width: 400px;
  width: 100%;
  text-align: center;
`;

const FormCard = styled(Tile)`
  margin-top: 1.5rem;
  padding: 1.5rem;
  text-align: left;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

// Subtítulo do formulário
const FormSubtitle = styled.p`
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #5a5a5a;
`;


export { CenteredContainer, StyledTile, FormCard, FormSubtitle };