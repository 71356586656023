import styled, { css } from "styled-components";
import { Button as CButton } from "@carbon/react";
import { globalStyles } from "../../../consts/globalStyles";
const width = "448px";

export const Article = styled.div`
  display: grid;
  width: ${width};
  gap: 25px;
  margin-top: 50px;
`;

export const Session = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
  .content {
    width: ${width};
    .p {
      padding-bottom: ${globalStyles.size.sm};
    }
  }
  .endIntegration {
    &:disabled {
      background-color: #c6c6c6;
    }
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0 10px 0;
  .listItemDirectionEnd {
    justify-self: end;
    .buttom {
      color: ${globalStyles.colors.linkPrimary};
    }
  }
`;

export const TextWithLink = styled.div`
  padding: ${globalStyles.size.sm} 0;
  .linkColor {
    padding-left: 5px;
    color: ${globalStyles.colors.linkPrimary};
    cursor: pointer;
  }
`;

export const ButtonSet = styled.div`
  gap: 10px;
  padding: 24px 0;
  display: flex;
`;

export const Button = styled(CButton)`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-inline-end: 10px;
  max-inline-size: 100%;

  .icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${(props) => {
    if (props.primary) {
      return css`
        background-color: #75fb9f;
        color: #000;
      `;
    }

    if (props.kind === "tertiary") {
      return css`
        color: #000;
        border-color: #000;
      `;
    }

    if (props.kind === "ghost") {
      return css`
        color: #636569;
      `;
    }
  }}
`;
