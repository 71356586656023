import styled from "styled-components";

const session = styled.div`
  display: grid;
  justify-content: center;
  .toBack {
    margin-top: 25px;
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const title = styled.div`
  width: 350px;
  h3 {
    margin-bottom: 24px;
  }
  h4 {
    margin-bottom: 48px;
  }
`;

const form = styled.form`
  width: 350px;
  gap: 25px;
  display: grid;
  .submitBtn {
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export { session, title, form };
