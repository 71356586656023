import React, { useState } from "react";
import { TextInput, Button, Form } from "@carbon/react";
import { useNavigate } from "react-router-dom";
import { startConfig } from "../../services/StartConfig.service";

import * as S from "./startConfigsAdmin.style";
import { createProducts } from "../../services/Products.service";

export function StartConfigsAdmin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [formValue, setFormValue] = useState("");

  // Função de verificação de login
  const handleLogin = () => {
    if (username === "admin" && password === "123498") {
      setIsAuthenticated(true);
    } else {
      alert("Usuário ou senha incorretos");
    }
  };

  // Função de logout
  const handleLogout = () => {
    setIsAuthenticated(false);
    setUsername("");
    setPassword("");
    setFormValue("");
  };

  // Função de envio do formulário
  const handleGenerateTables = async () => {
    try {
      const response = await startConfig();

      if (response) {
        alert('Geração da tabela realizada com sucesso, siga para o proximo passo');
      }
    } catch (error) {
      const jsonError = JSON.parse(error.message);
      if (jsonError.status === 401) {
        window.location.href = "/";
      }
      alert(jsonError.error);
    }
  };

  // Função para "Forçar preenchimento de tabela produtos"
  const handleForceFill = async () => {
      try {
        const response = await createProducts();
        if (response) {
          alert('Geração de produtos realizada com sucesso');
        }
      } catch (error) {
        const jsonError = JSON.parse(error.message);
        if (jsonError.status === 401) {
          window.location.href = "/";
        }
      alert(jsonError.error);
      }
  };

  return (
    <S.CenteredContainer>
      <S.StyledTile>
        {!isAuthenticated ? (
          <div>
            <h2>Login</h2>
            <Form onSubmit={(e) => e.preventDefault()}>
              <TextInput
                id="username"
                labelText="Usuário"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{ marginBottom: "1rem" }}
              />
              <TextInput
                id="password"
                type="password"
                labelText="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                onClick={handleLogin}
                kind="primary"
                style={{ marginTop: "1rem" }}
              >
                Entrar
              </Button>
            </Form>
          </div>
        ) : (
          <div style={{ textAlign: "left" }}>
            <h2>Formulário</h2>

            <S.FormCard>
              {/* <Form onSubmit={(e) => e.preventDefault()}>
                <TextInput
                  id="inputField"
                  labelText="Nome da Companhia"
                  value={formValue}
                  onChange={(e) => setFormValue(e.target.value)}
                  required
                />
                <Button
                  onClick={handleSubmitForm}
                  kind="primary"
                  style={{ marginTop: "1rem" }}
                >
                  Enviar
                </Button> */}
              <S.FormSubtitle>
                Este Botões servem para gerar as tabelas no banco de dados
                referente a companhia, vale resaltar que a campanhia que ira ser
                gerada e referente ao usuario logado, no login comun dp
                aplicação
              </S.FormSubtitle>
              <hr />
              <Button
                onClick={handleGenerateTables}
                kind="danger--ghost"
                size="md"
              >
                Criar tabelas no banco de dados
              </Button>
              <hr />
              <Button
                onClick={handleForceFill}
                kind="ghost"
                size="md"
              >
                Força preenchimento de tabela produtos
              </Button>
              {/* </Form> */}
            </S.FormCard>
            <Button
              onClick={handleLogout}
              kind="danger"
              style={{ marginTop: "1rem", width: "100%" }}
            >
              Sair da area administrativa
            </Button>
          </div>
        )}
      </S.StyledTile>
    </S.CenteredContainer>
  );
}
