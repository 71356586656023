import styled, { css } from "styled-components";
import {
  ComposedModal as CComposedModal,
  Button as CButton,
} from "@carbon/react";

export const ComposedModal = styled(CComposedModal)`
  .cds--btn--primary {
    background-color: #75fb9f;
    color: black;
  }
`;

export const SectionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
`;

export const Button = styled(CButton)`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-inline-end: 10px;
  max-inline-size: 100%;

  .icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${(props) => {
    if (props.primary) {
      return css`
        background-color: #75fb9f;
        color: #000;
      `;
    }

    if (props.kind === "tertiary") {
      return css`
        color: #000;
        border-color: #000;
      `;
    }
  }}
`;

export const ModalButtonSet = styled.div`
  display: flex;
`;

export const TargetContainer = styled.div`
  display: flex;
  gap: 40px;
`;

export const Channel = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 30%;
`;
