import styled from "styled-components";

export const Tag = styled.div`
  width: fit-content;
  border-radius: 500px;
  padding: 4px 8px;
  color: white;
  font-size: 14px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "grey"};
`;
