import React, { useContext } from "react";
import {
  DataTable,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableExpandHeader,
  TableBody,
  TableExpandRow,
  TableCell,
  TableExpandedRow,
  TextInput,
} from "@carbon/react";
import { CreatePlanContext } from "../../../../contexts/CreatePlanContext";

import * as S from "../LeadTimeStep.style";

export default function CategoryTable({
  getLeadTime,
  defineLeadTime,
  disableInputs,
}) {
  const { categories } = useContext(CreatePlanContext);

  const headers = [
    { title: "Categorias", key: "name" },
    { title: "Tempo de entrega", key: "leadTime" },
  ];
  return (
    <DataTable rows={categories} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getTableContainerProps,
      }) => (
        <TableContainer {...getTableContainerProps()}>
          <S.Table {...getTableProps()} size="md">
            <TableHead>
              <TableRow>
                <TableExpandHeader id="expand" />
                {headers.map((header, i) => (
                  <TableHeader
                    id={header.title}
                    {...getHeaderProps({ header })}
                  >
                    {header.title}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIdx) => {
                return (
                  <React.Fragment key={row.id}>
                    <TableExpandRow {...getRowProps({ row })}>
                      <TableCell>
                        {rows[rowIdx].cells[0].value || "Categoria"}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "180px",
                          }}
                        >
                          <TextInput
                            placeholder="Tempo em dias"
                            type="text"
                            value={getLeadTime(row.id) || ""}
                            onChange={(e) =>
                              defineLeadTime(row.id, e.target.value)
                            }
                            disabled={disableInputs}
                          />{" "}
                          dias
                        </div>
                      </TableCell>
                    </TableExpandRow>
                    <TableExpandedRow colSpan={headers.length + 1}>
                      {categories[rowIdx].products?.map((product) => (
                        <S.Product key={product.id}>
                          {product.id} - {product.produto}
                        </S.Product>
                      ))}
                    </TableExpandedRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </S.Table>
        </TableContainer>
      )}
    </DataTable>
  );
}
