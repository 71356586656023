import axios from "./BaseService";

const API_URL =
  `${process.env.REACT_APP_API_URL}` ||
  "http://localhost:3001";

export async function createCompany(company) {
  const usersUrl = `${API_URL}/api/company`;
  const response = await axios.post(usersUrl, company);
  return response.data;
}
