import React from "react";
import PropTypes from "prop-types";

import {
  MainStyles,
  ContainerStyles,
  FillerStyles,
  LabelStyles,
} from "./ProgressBar.style";
const ProgressBar = ({ label, completed = 0 }) => {
  return (
    <MainStyles>
      <ContainerStyles>
        <FillerStyles width={completed}>
          <LabelStyles>{label ? `${label}` : null}</LabelStyles>
        </FillerStyles>
      </ContainerStyles>
    </MainStyles>
  );
};

ProgressBar.propTypes = {
  label: PropTypes.node,
  completed: PropTypes.number,
};

export default ProgressBar;
