import React from "react";

import * as S from "./StockTag.style";

export default function StockTag({ isOver }) {
  return (
    <S.Tag backgroundColor={isOver ? "green" : "red"}>
      {isOver ? "Estoque em excesso" : "Estoque em falta"}
    </S.Tag>
  );
}
