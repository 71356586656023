import styled from "styled-components";
import { Button as CButton } from '@carbon/react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 0px 5px;
`;

export const SeasonSet = styled.div`
  gap: 24px;
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ButtonSeason = styled(CButton)`
  align-items: center;
  display: flex;
  padding-inline-end: 15px;
  max-inline-size: 100%;
  color: #000;
  background-color: #fff;
  border-radius: 500px;
  font-size: 12px;
  &:hover {
    background-color: #75fb9f;
    color: #000;
  }
  &.selected {
    background-color: #75fb9f;
    &:hover {
      opacity: 0.8;
    }
  }
`;