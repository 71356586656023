import styled, { css } from "styled-components";
import {
  Button as CButton,
  Column as CColumn,
  Tab as CTab,
  Table as CTable,
} from "@carbon/react";

export const Section = styled.section``;

export const Title = styled.article`
  h4 {
    font-size: 24px;
    font-weight: 600;
  }

  display: flex;
  gap: 24px;
  padding: 24px 0;
  align-items: center;
`;

export const H3 = styled.h3`
  font-size: 32px;
  font-weight: 600;
`;

export const H4 = styled.h4`
  font-size: 18px;
  font-weight: 600;
`;

export const Column = styled(CColumn)`
  background-color: #0000000a;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 72px;
`;

export const Text = styled.p`
  color: ${(props) => (props.black ? "black" : "#7d7d7d")};
  font-size: 14px;
  font-weight: 400;
`;

export const Button = styled(CButton)`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-inline-end: 10px;
  max-inline-size: 100%;

  .icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${(props) => {
    if (props.primary) {
      return css`
        background-color: #75fb9f;
        color: #000;
      `;
    }

    if (props.kind === "tertiary") {
      return css`
        color: #000;
        border-color: #000;
      `;
    }
  }}
`;

export const ButtonSet = styled.div`
  gap: 24px;
  padding: 24px 0;
  display: flex;
`;

export const Taglist = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Tag = styled.div`
  background-color: #fff;
  border-radius: 100px;
  color: #b5b7ba;
  font-size: 14px;
  font-weight: 400;
  gap: 8px;
  padding: 16px;
  text-align: left;
`;

export const List = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 8px 0;

  input {
    background-color: white;
    border: none;
  }
`;

export const Tab = styled(CTab)`
  ${(props) => {
    if (props.completed) {
      return css`
        color: #5bce7f !important;
        border-block-end: 2px solid #5bce7f !important;
      `;
    }
    if (props.selected) {
      return css`
        border-block-end: 2px solid #000 !important;
      `;
    }
  }};
`;
export const ButtonGraphsGroup = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const ButtonGraphs = styled.button`
  background-color: white;
  border-radius: 24px;
  border: none;
  padding: 12px;
  cursor: pointer;

  border: ${({ isSelected }) => isSelected && "2px solid #5bce7f"};

  p {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
  }
`;

export const Table = styled(CTable)`
  th {
    background-color: black !important;
    color: white !important;
    border-color: gray;
  }

  td {
    background-color: white !important;
    padding: 16px;
    font-weight: 600;

    input {
      border: none;
    }
  }
`;