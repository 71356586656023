import React, { useContext, useState } from "react";
import {
  Column,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@carbon/react";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";
import Icon from "../../../assets/icons/Chart--histogram.svg";
import Graphic from "../../../assets/icons/graphic.svg";

import * as S from "./SalesGraphStep.style";

import { CreatePlanContext } from "../../../contexts/CreatePlanContext";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { faker } from "@faker-js/faker/locale/ar";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const header = ["Mês", "Venda (unidades)", "Venda (valor)", "Participação"];

export default function SalesGraphStep({ onBackStep, onCompleteStep }) {
  const {
    selectedMonths,
    selectedCurveId,
    setSelectedCurveId,
    writeMonthLabel,
  } = useContext(CreatePlanContext);

  const tableData = selectedMonths.map((month) => ({
    month: writeMonthLabel(month),
    salesUnits: faker.number.int(1000),
    salesValue: faker.number.int(10000),
    participation: faker.number.int(100),
  }));

  const months = tableData.flatMap((month) => month.month);

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={6} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt="" />
          <h4>Curva de venda</h4>
        </S.Title>
        <S.Text>
          A análise da curva de venda é fundamental para compreender o
          comportamento das vendas ao longo do tempo. Geramos uma curva de venda
          recomendada para você com base no histórico de vendas. Analise essa e
          outras curvas e escolha qual vai usar como modelo de curva de venda do
          ano.
        </S.Text>
      </Column>
      <S.Column lg={10} md={4} sm={4}>
        <S.H3>Curva sugerida</S.H3>
        <Tabs>
          <TabList contained>
            <S.Tab>Gráfico</S.Tab>
            <S.Tab>Tabela</S.Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Chart
                type="bar"
                data={{
                  labels: months,
                  datasets: [
                    // {
                    //   type: "line",
                    //   label: "Unidades Vendidas",
                    //   borderColor: "rgb(255, 99, 132)",
                    //   borderWidth: 2,
                    //   fill: false,
                    //   data: tableData.flatMap((data) => data.salesUnits),
                    // },
                    {
                      type: "bar",
                      label: "Valor de Vendas",
                      backgroundColor: "rgb(75, 192, 192)",
                      data: tableData.flatMap((data) => data.salesValue),
                      borderColor: "white",
                      borderWidth: 2,
                    },
                  ],
                }}
              />
            </TabPanel>
            <TabPanel>
              <S.Table aria-label="tabela de venda">
                <TableHead>
                  <TableRow>
                    {header.map((head) => (
                      <TableHeader>{head}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((data) => (
                    <TableRow>
                      <TableCell>{data.month}</TableCell>
                      <TableCell>{data.salesUnits}</TableCell>
                      <TableCell>
                        {data.salesValue.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </TableCell>
                      <TableCell>{data.participation}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </S.Table>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <S.H4>Outras curvas</S.H4>
        <S.ButtonGraphsGroup>
          <S.ButtonGraphs
            isSelected={selectedCurveId === 0}
            onClick={() => {
              setSelectedCurveId(0);
            }}
          >
            <p>Curva sugerida</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>

          <S.ButtonGraphs
            isSelected={selectedCurveId === 1}
            onClick={() => {
              setSelectedCurveId(1);
            }}
          >
            <p>Venda digital</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>

          <S.ButtonGraphs
            isSelected={selectedCurveId === 2}
            onClick={() => {
              setSelectedCurveId(2);
            }}
          >
            <p>Invernais</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>

          <S.ButtonGraphs
            isSelected={selectedCurveId === 3}
            onClick={() => {
              setSelectedCurveId(3);
            }}
          >
            <p>Verão</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>

          <S.ButtonGraphs
            isSelected={selectedCurveId === 4}
            onClick={() => {
              setSelectedCurveId(4);
            }}
          >
            <p>Comercial</p>
            <img src={Graphic} alt="" />
          </S.ButtonGraphs>
        </S.ButtonGraphsGroup>

        <S.ButtonSet>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(4);
            }}
          >
            <ArrowLeft /> Voltar
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              onCompleteStep(4);
            }}
          >
            Concluir planejamento
          </S.Button>
        </S.ButtonSet>
      </S.Column>
    </Grid>
  );
}
