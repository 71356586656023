import { Button } from "@carbon/react";
import { TrashCan } from "@carbon/icons-react";
import { faker } from "@faker-js/faker/locale/pt_BR";
import * as S from "./FinalResults.style";

const mockData = [
  {
    plan: "Simulação",
    category: "Camiseta",
    date: "22/04/2024",
    list: [
      "Excesso de 10.000 pcs em Abril. Sugestão de transferir para Junho.",
      "Abertura de compra até Dezembro/2024: 105.500 peças.",
      "Curva de Recebimento sugerida 3 trimestre: 20.000 peças para o mês de Julho; 10.500 peças para Agosto; 15.500 peças para Setembro.",
      "Curva de Recebimento sugerida 4 trimestre: 30.000 peças para o mês de Outubro; 24.500 peças para Novembro; 5.000 peças para Dezembro",
    ],
    message: {
      type: "success",
      content:
        "Você irá gerar uma redução de 34.000 pcs em seu estoque médio, uma economia de R$ 850.000 reais.",
    },
  },
  {
    plan: "Simulação 1",
    category: "Polo",
    date: "22/04/2024",
    list: [
      "Excesso de 27.000 pcs em Abril. ",
      "Não há abertura de compra até Dezembro/2024.",
      "Alterações sugeridas: analisar carteira à receber de 47.702 peças para possível redução das entradas e prorrogação para o segundo semestre.",
    ],
    message: {
      type: "error",
      content:
        "Você tem oportunidade de reduzir o seu estoque para 150 dias, o que levaria a uma economia de capital investido de R$ 1.075.00,00.",
    },
  },
  {
    plan: "Simulação",
    category: "Bermudas",
    date: "22/04/2024",
    list: [
      "Abertura de compra até Dezembro/2024: 19.700 peças.",
      "Curva de Reebimento sugerida 3 trimestre: 2.500 peças para o mês de Julho; 2.500 peças para Agosto; 3.700 peças para Setembro.",
      "Curva de Recebimento sugerida 4 trimestre: 7.000 peças para o mês de Outubro; 4.000 peças para Novembro.",
    ],
    message: {
      type: "success",
      content:
        "Você irá gerar uma redução de 1.200 pcs em seu estoque médio, uma economia de R$ 60.000 reais.",
    },
  },
];

export default function FinalResults() {
  return (
    <S.Container>
      {mockData.map(({ plan, category, date, list, message }) => (
        <S.Box>
          <div className="header">
            <div className="grid">
              <div className="cell">
                <p className="t1">Plano</p>
                <p className="t2">{plan}</p>
              </div>
              <div className="cell">
                <p className="t1">Categoria</p>
                <p className="t2">{category}</p>
              </div>
              <div className="cell">
                <p className="t1">Data</p>
                <p className="t2">{date}</p>
              </div>
            </div>
            <Button
              hasIconOnly
              iconDescription="remover"
              renderIcon={TrashCan}
              kind="ghost"
            />
          </div>

          <ul className="list">
            {list.map((item) => (
              <li>{item}</li>
            ))}
          </ul>

          <S.Message type={message.type}>{message.content}</S.Message>
        </S.Box>
      ))}
    </S.Container>
  );
}
