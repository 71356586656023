import styled from "styled-components";
import {
  Button as CButton,
  Modal as CModal,
  Tab as CTab,
  TabPanel as CTabPanel,
} from "@carbon/react";

export const Button = styled(CButton)`
  background-color: ${({ green }) => (green ? "#68E58F" : "black")};
  color: ${({ green }) => (green ? "black" : "white")};
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding-top: 24px;
`;

export const Modal = styled(CModal)`
  .cds--modal-content {
    padding: 24px;
  }
`;

export const GrayBox = styled.div`
  background-color: #eaeaeb;
  padding: 8px;
  border-top: 2px solid #caccce;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Container = styled.div`
  width: 100%;
  text-align: left;

  h1 {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    background-color: #dfe0e1;
    padding: 16px;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 16px;
    text-align: left;
  }

  .toggle {
    margin: 16px;
  }
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 4px;
  table-layout: fixed;
  width: 100%;

  thead {
    background-color: white;

    tr {
      th {
        padding: 8px;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      td,
      th {
        background-color: #dfe0e1;
        font-size: 12px;
        padding: 8px;
        white-space: nowrap;
      }

      th {
        font-weight: 600;
      }

      &.start-group {
        td,
        th {
          border-top: 8px solid #f4f5f5;
        }
      }

      &.white {
        td,
        th {
          background-color: white;

          input {
            width: 100%;
            border: none;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ isOver }) => (isOver ? "#fc7d84" : "#41A05F")};

  > div {
    padding: 4px;
    background-color: ${({ isOver }) => (isOver ? "#fc7d84" : "#41A05F")};
    color: white;
    border-radius: 16px;
  }
`;

export const Link = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;

  button {
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: 500;
    margin-right: 8px;
    cursor: pointer;

    &.simulate {
      background-color: #75fb9f;
    }

    .confirm {
      fill: #41a05f;
    }

    .reject {
      fill: #ff4242;
    }
  }
`;

export const Carrousel = styled.div`
  position: relative;

  > button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: #27733f;
    display: flex;
    font-size: 24px;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: pointer;
    z-index: 999;

    &.prev {
      left: 0;

      &::before {
        content: "<";
      }
    }

    &.next {
      right: 0;
      &::before {
        content: ">";
      }
    }
  }

  .presentation {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .markGroup {
    display: flex;
    justify-content: center;
    gap: 4px;

    .mark {
      background-color: #caccce;
      width: 36px;
      height: 4px;
      border-radius: 4px;

      &.active {
        background-color: #777a7e;
      }
    }
  }
`;

export const Item = styled.div`
  min-width: 100%;
  text-align: left;
  transform: translateX(${({ slide }) => slide * -100}%);
  transition: 1s cubic-bezier(1, 0, 0, 1);
  width: 100%;
  padding: 16px 48px;
  gap: 8px;
  display: flex;
  flex-direction: column;

  h6 {
    font-size: 12px;
    font-weight: 500;
  }
`;
export const TabPanel = styled(CTabPanel)`
  padding: 0;
`;

export const Tab = styled(CTab)`
  &[aria-selected="false"] {
    background: transparent !important;
  }

  &[aria-selected="true"] {
    font-weight: 600 !important;
    background-color: #dfe0e1 !important;
    box-shadow: inset 0 2px 0 0 #75fb9f !important;
  }
`;

export const Selected = styled.div`
  align-items: center;
  background-color: #dfe0e1;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 16px;

  > p {
    font-size: 12px;
    font-weight: 700;
  }
`;
