import styled from "styled-components";
import { Button as CButton } from "@carbon/react";

export const ToggleBox = styled.div`
  display: flex;
  padding: 20px;
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 4px;
  table-layout: fixed;

  thead {
    background-color: white;

    tr {
      th {
        padding: 8px;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      td,
      th {
        background-color: #dfe0e1;
        font-size: 12px;
        padding: 8px;
        white-space: nowrap;
      }

      th {
        font-weight: 600;
        text-align: left;
      }

      &.start-group {
        td,
        th {
          border-top: 8px solid #f4f5f5;
        }
      }

      &.white {
        td,
        th {
          background-color: white;

          input {
            width: 100%;
            border: none;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ isOver }) => (isOver ? "#fc7d84" : "#41A05F")};

  > div {
    padding: 4px;
    background-color: ${({ isOver }) => (isOver ? "#fc7d84" : "#41A05F")};
    color: white;
    border-radius: 16px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 20px;
`;

export const Button = styled(CButton)`
  background-color: ${({ green }) => (green ? "#68E58F" : "black")};
  color: ${({ green }) => (green ? "black" : "white")};
`;
