import React from "react";
import blig from "./logos/bling.png";
import contaAzul from "./logos/contaAzul.png";
import linx from "./logos/link.png";
import omie from "./logos/omie.png";
import tiny from "./logos/tiny.png";

export default function RenderLogo(typeLogo) {
  const chooseLogo = (typeLogo) => {
    switch (typeLogo) {
      case "bling":
        return blig;
      case "contaAzul":
        return contaAzul;
      case "omie":
        return omie;
      case "tiny":
        return tiny;
      default:
        return linx;
    }
  };

  return (
    <img src={chooseLogo(typeLogo)} alt="Logos" />
  );
}
