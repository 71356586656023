import React from "react";

import * as S from "./IndexesSection.style";

import StockTag from "../StockTag/StockTag";

export default function IndexesSection() {
  return (
    <S.Container>
      <div>
        <S.Title>Situação atual do estoque</S.Title>
        <S.StockSection>
          <S.Box twoRows>
            <StockTag />
            <S.Number>-6.525</S.Number>
          </S.Box>
          <S.Box twoRows>
            <StockTag isOver />
            <S.Number>+7.147</S.Number>
          </S.Box>
        </S.StockSection>
      </div>

      <S.RightContainer>
        <div>
          <S.Title>Total de ações em aberto</S.Title>
          <S.Box>
            <S.Number>234</S.Number>
          </S.Box>
        </div>

        <div>
          <S.Title>ROI total em ações</S.Title>
          <S.Box>
            <S.Number>R$ 1.256.170</S.Number>
          </S.Box>
        </div>

        <div>
          <S.Title>Status Geral</S.Title>
          <S.Box>
            <S.Number>12% / 100%</S.Number>
          </S.Box>
        </div>
      </S.RightContainer>
    </S.Container>
  );
}
