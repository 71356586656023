import React, { useState } from "react";
import { TextInput, Select, SelectItem, Checkbox } from "@carbon/react";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";

import * as S from "./UserInfoStep.style";

export default function UserInfoStep({
  handleInputChange,
  handleNextStep,
  handlePreviousStep,
  formData,
}) {
  const selectItemsCompany = [
    { item: "Auto Peças e Serviços Automotivos", value: 1 },
    { item: "Cosméticos e Higiene Pessoal", value: 2 },
    { item: "Drogarias e Farmácias", value: 3 },
    { item: "Estética e Cabeleireiros", value: 4 },
    { item: "Franquias", value: 5 },
    { item: "Livrarias Papelarias e Afins", value: 6 },
    { item: "Materiais para Construção", value: 7 },
    { item: "Móveis Eletro e Depto.", value: 8 },
    { item: "Óticas e Joalherias", value: 9 },
    { item: "Outros Serviços", value: 10 },
    { item: "Outros Varejos", value: 11 },
    { item: "Postos de Gasolina", value: 12 },
    { item: "Recreação e Lazer", value: 13 },
    { item: "Supermercados e Hipermercados", value: 14 },
    { item: "Turismo e Transporte", value: 15 },
    { item: "Varejo Alimentício Especializado", value: 16 },
    { item: "Vestuário e/ou Artigos Esportivos", value: 17 },
    { item: "Veterinárias e Pet-shops", value: 18 },
  ];

  const selectItemsEmployees = [
    { item: "1-9", value: 1 },
    { item: "10-49", value: 2 },
    { item: "50-99", value: 3 },
    { item: "100 ou mais", value: 4 },
  ];

  const areAllComplete = () => {
    return (
      formData.name &&
      formData.companyName &&
      formData.sector &&
      formData.employeeNumber
    );
  };

  const handleCheck = (e) => {
    const event = { target: { name: "mailing", value: e.target.checked } };
    handleInputChange(event);
  };

  return (
    <S.Form>
      <TextInput
        type="text"
        placeholder="Seu Nome"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
      />
      <TextInput
        type="text"
        name="companyName"
        placeholder="Nome da sua empresa"
        value={formData.companyName}
        onChange={handleInputChange}
      />
      <Select
        id={`select-company-sector`}
        defaultValue="000"
        labelText=""
        name="sector"
        value={formData.sector}
        onChange={handleInputChange}
      >
        <SelectItem
          key="company-null-000"
          value="000"
          text="Setor da sua empresa"
        />
        {selectItemsCompany.map(({ value, item }) => (
          <SelectItem key={`${value}-${item}`} value={value} text={item} />
        ))}
      </Select>
      <Select
        id={`select-number-employees`}
        labelText=""
        defaultValue="000"
        name="employeeNumber"
        value={formData.employeeNumber}
        onChange={handleInputChange}
      >
        <SelectItem
          key="employees-null-000"
          value="000"
          text="Número de funcionários"
          checked
        />
        {selectItemsEmployees.map(({ value, item }) => (
          <SelectItem key={`${value}-${item}`} value={value} text={item} />
        ))}
      </Select>
      <div style={{ marginTop: "25px" }}>
        <Checkbox
          id="mailing"
          labelText="Eu aceito participar da lista de e-mails da Sumply"
          checked={formData.mailing}
          onChange={(e) => handleCheck(e)}
        />
      </div>
      <S.ButtonSet>
        <S.Button kind="secondary" onClick={handlePreviousStep}>
          <ArrowLeft /> Voltar
        </S.Button>
        <S.Button
          primary
          renderIcon={() => <ArrowRight />}
          onClick={handleNextStep}
          disabled={!areAllComplete()}
        >
          Avançar
        </S.Button>
      </S.ButtonSet>
    </S.Form>
  );
}
