import React, { useContext, useEffect, useState } from "react";
import { Column, Grid } from "@carbon/react";
import { ArrowRight } from "@carbon/icons-react";
import moment from "moment";
import "moment/locale/pt-br";

import * as S from "./CreateSeasonStep.style";

import { CreatePlanContext } from "../../../contexts/CreatePlanContext";

import Chart from "../../../assets/icons/Chart--bar.svg";
import TargetSection from "./TargetSection/TargetSection";
import PeriodSection from "./PeriodSection/PeriodSection";
import SelectedPeriodSection from "./SelectedPeriodSection/SelectedPeriodSection";
import CustomPeriodSelection from "./CustomPeriodSelection/CustomPeriodSelection";

export default function CreateSeasonStep({ onCompleteStep }) {
  const { selectedMonths, setSelectedMonths, periodTarget } =
    useContext(CreatePlanContext);
  const [period, setPeriod] = useState();
  const [dates, setDates] = useState({});

  const months = Array.from({ length: 36 }).map((entry, i) => {
    const currentDate = moment().subtract(moment().month() - i, "months");
    return {
      id: currentDate.month(),
      month: currentDate.locale("pt-br").format("MMM"),
      year: currentDate.year(),
    };
  });

  const monthsByYear = Object.groupBy(months, (month) => {
    return month.year;
  });

  const periodOptions = {
    next6months: "Próximos 6 meses",
    eoy: "Até o final do ano",
    next12months: "Próximos 12 meses",
    eony: "Até o final do próximo ano",
    custom: "Personalizado",
  };

  useEffect(() => {
    if (!period) return;
    let startDate = undefined;
    let endDate = undefined;
    if (period !== "custom") {
      switch (period) {
        case "next6months":
          startDate = moment().format("YYYY-MM-DD");
          endDate = moment().add(6, "months").format("YYYY-MM-DD");
          setSelectedMonths(
            Array.from({ length: 6 }).map((entry, i) => {
              return `${moment().add(i, "months").month()}-${moment()
                .add(i, "months")
                .year()}`;
            })
          );
          break;
        case "next12months":
          startDate = moment().format("YYYY-MM-DD");
          endDate = moment().add(12, "months").format("YYYY-MM-DD");
          setSelectedMonths(
            Array.from({ length: 12 }).map((entry, i) => {
              return `${moment().add(i, "months").month()}-${moment()
                .add(i, "months")
                .year()}`;
            })
          );
          break;
        case "eoy":
          startDate = moment().format("YYYY-MM-DD");
          endDate = moment().endOf("year").format("YYYY-MM-DD");
          setSelectedMonths(
            Array.from({
              length: moment().endOf("year").month() - moment().month() + 1,
            }).map((entry, i) => {
              return `${moment().add(i, "months").month()}-${moment()
                .add(i, "months")
                .year()}`;
            })
          );
          break;
        case "eony":
          startDate = moment().format("YYYY-MM-DD");
          endDate = moment()
            .add(12, "months")
            .endOf("year")
            .format("YYYY-MM-DD");
          setSelectedMonths(
            Array.from({
              length:
                moment().endOf("year").month() + 12 - moment().month() + 1,
            }).map((entry, i) => {
              return `${moment().add(i, "months").month()}-${moment()
                .add(i, "months")
                .year()}`;
            })
          );
          break;
        default:
          break;
      }
      // setDates({
      //   startDate: startDate ? new Date(startDate) : startDate,
      //   endDate: endDate ? new Date(endDate) : endDate,
      // });
    } else {
      defineCustomMonths();
    }
  }, [period, dates]);

  const defineCustomMonths = () => {
    let newSelectedMonths = [];

    if (dates.startDate && dates.endDate) {
      const start = moment(dates.startDate);
      const end = moment(dates.endDate);

      const monthsBetween = end.diff(start, "months");

      newSelectedMonths = Array.from({ length: monthsBetween + 1 }).map(
        (entry, i) => {
          const currentMonth = start.clone().add(i, "months");
          return `${currentMonth.month()}-${currentMonth.year()}`;
        }
      );
    }

    setSelectedMonths(newSelectedMonths);
  };

  return (
    <Grid style={{ marginTop: 40 }} fullWidth>
      <Column lg={6} md={16} sm={16}>
        <S.Title>
          <img src={Chart} alt="Período de Planejamento" />
          <h4>Período de planejamento</h4>
        </S.Title>
        <S.Text>
          Para iniciar seu planejamento estratégico de vendas e estoque você
          precisa delimitar o período para o qual irá se planejar e sua meta de
          crescimento durante esse intervalo. Nós te enviaremos dicas e você
          poderá fazer ajustes de rota de acordo com suas vendas. Quando esse
          período estiver perto de acabar nós te avisaremos para criar o
          próximo.
        </S.Text>
      </Column>
      <Column
        lg={10}
        md={16}
        sm={16}
        style={{
          backgroundColor: "#0000000A",
          padding: "24px 24px",
        }}
      >
        <S.Text largerPadding>
          Selecione o período para o qual você deseja fazer seu planejamento e
          qual a projeção do valor que você deseja atingir dentro desse período.
        </S.Text>

        <S.SessionsContainer>
          <TargetSection />

          <PeriodSection
            periodOptions={periodOptions}
            period={period}
            setPeriod={setPeriod}
          />

          <SelectedPeriodSection
            monthsByYear={monthsByYear}
            selectedMonths={selectedMonths}
          />
        </S.SessionsContainer>

        {period === "custom" && (
          <CustomPeriodSelection dates={dates} setDates={setDates} />
        )}

        <S.ButtonSet>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              onCompleteStep(0);
            }}
            disabled={!periodTarget || !selectedMonths.length}
          >
            Próxima
          </S.Button>
        </S.ButtonSet>
      </Column>
    </Grid>
  );
}
