import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-right: solid 1px rgba(22, 22, 22, 0.25);
  padding-right: 1.5rem;
  min-width: 175px;

  input {
    background-color: white;
    border-block-end: none;
  }

  label {
    text-align: center;
  }
`;

export const Label = styled.p`
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 12px;
  font-weight: bold;
`;
