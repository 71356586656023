import React from "react";
import {
  Star,
  StarFilled,
  Rocket,
  Flash,
  FlashFilled,
  HealthCross,
  Box,
} from "@carbon/icons-react";
import * as S from "./Card.style";
import { BoxFilled, HealthCrossFilled, RocketFilled } from "../LocalIcons";

export default function Card(props) {
  const renderRange = (score, max) => {
    const blankIcon = (i) => {
      switch (props.title) {
        case "Negócio":
        case "Vendas":
        case "Estoque":
          return <Star key={i.toString()} color="#41A05F" />;
        case "Performance":
          return <Rocket key={i.toString()} color="#41A05F" />;
        case "Velocidade":
          return <Flash key={i.toString()} color="#41A05F" />;
        case "Saúde":
          return <HealthCross key={i.toString()} color="#41A05F" />;
        case "Cobertura":
          return <Box key={i.toString()} color="#41A05F" />;

        default:
          return <Star key={i.toString()} color="#41A05F" />;
      }
    };
    const filledIcon = (i) => {
      switch (props.title) {
        case "Negócio":
        case "Vendas":
        case "Estoque":
          return <StarFilled key={i.toString()} color="#41A05F" />;
        case "Performance":
          return <RocketFilled />;
        case "Velocidade":
          return <FlashFilled key={i.toString()} color="#41A05F" />;
        case "Saúde":
          return <HealthCrossFilled />;
        case "Cobertura":
          return <BoxFilled />;
        default:
          return <StarFilled key={i.toString()} color="#41A05F" />;
      }
    };
    return Array.from({ length: max }).map((item, i) =>
      i < score ? filledIcon(i) : blankIcon(i)
    );
  };

  const renderContent = (size) => {
    if (size === "small" && !props.content) {
      return (
        <>
          <S.subtitle>{props.subtitle}</S.subtitle>
          <S.title>{props.title}</S.title>
          <div className="info">
            {props.score && (
              <S.range>
                {typeof props.score === "number" && renderRange(props.score, 4)}
              </S.range>
            )}
            {props.tag && <S.tag>{props.tag}</S.tag>}
            {props.bigInfo && (
              <S.bigInfo style={{ marginLeft: "auto" }}>
                {props.bigInfo}
              </S.bigInfo>
            )}
          </div>
        </>
      );
    }
    if (props.content) {
      return (
        <>
          <S.subtitle>{props.subtitle}</S.subtitle>
          <S.title>{props.title}</S.title>
          <div className="info">{props.content}</div>
        </>
      );
    }
    return (
      <>
        {props.icon && <S.icon>{props.icon}</S.icon>}
        {props.subtitle && <S.subtitle>{props.subtitle}</S.subtitle>}
        <S.title>{props.title}</S.title>
        {props.score && (
          <S.range>
            {typeof props.score === "number" && renderRange(props.score, 4)}
          </S.range>
        )}
        {props.tag && <S.tag>{props.tag}</S.tag>}
        {props.bigInfo && <S.bigInfo>{props.bigInfo}</S.bigInfo>}
      </>
    );
  };

  if (props.parent && props.child) {
    return (
      <S.wrapperParentChild>
        <S.card style={props.style}>{renderContent()}</S.card>
      </S.wrapperParentChild>
    );
  }
  if (props.parent && !props.child) {
    return (
      <S.wrapperParent>
        <S.card style={props.style}>{renderContent()}</S.card>
      </S.wrapperParent>
    );
  }
  if (!props.parent && props.child) {
    return (
      <S.wrapperChild>
        <S.cardSmall style={props.style}>{renderContent("small")}</S.cardSmall>
      </S.wrapperChild>
    );
  }
  return (
    <S.wrapper>
      <S.cardSmall style={props.style}>{renderContent()}</S.cardSmall>
    </S.wrapper>
  );
}
