import styled from "styled-components";

export const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
`;

export const Container = styled.div`
  border: solid 2px white;
  padding-right: 1.5rem;
  width: 100%;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
  text-align: center;
  margin-top: 0.5rem;
`;

export const Calendar = styled.div`
  margin: 1rem 0rem;

  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    padding: 0 10px;
    > span {
      text-transform: capitalize;
      text-align: center;
      font-size: 14px;
      line-height: 21px;
      &.year {
        transform: rotateZ(90deg);
        position: absolute;
        right: -15px;
        top: calc(50% - 10px);
      }
      &.old {
        opacity: 0.3
      }
      &.next {
        background-color: #75fb9f;
        color: green;
      }
    }
  }
`;

export const MonthsDiv = styled.div`
  border-bottom: solid 1px rgb(221 219 219);
`;

export const Inputs = styled.div`
  display: flex;
  gap: 8px;
  padding: 24px 0;

  input {
    background-color: white;
    border-block-end: none;
  }
`;
