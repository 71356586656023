import styled from "styled-components";

const session = styled.div`
  display: grid;
  justify-content: center;
  .submitBtn {
    margin-top: 25px;
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
  .linkCameBack {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    a {
      color: #05b33a !important;
    }
  }
`;

const sessionTitle = styled.div`
  width: 350px;
  h3 {
    margin-bottom: 24px;
  }
  h4 {
    margin-bottom: 48px;
  }
`;

const form = styled.form`
  width: 350px;
  gap: 25px;
  display: grid;
`;

export { session, form, sessionTitle };
