import React, { useState } from "react";
import { ProgressIndicator, ProgressStep } from "@carbon/react";

import * as S from "./AccountCreation.style";

import LayoutWithoutMenu from "../../layouts/LayoutWithoutMenu/LayoutWithoutMenu";
import CredentialsStep from "./CredentialsStep/CredentialsStep";
import UserInfoStep from "./UserInfoStep/UserInfoStep";
import IntegrationStep from "./IntegrationStep/IntegrationStep";

export default function AccountCreation() {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    companyName: "",
    sector: "",
    employeeNumber: "",
    mailing: false,
    erpIntegration: false,
  });

  const handleNextStep = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleInputChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <LayoutWithoutMenu>
      <S.ProgressWrapper>
        <ProgressIndicator currentIndex={currentStep}>
          <ProgressStep
            label="Autenticação"
            description="Passo 1: Quem é você?"
            secondaryLabel="Precisamos conhecê-lo"
          />
          <ProgressStep
            label="Cadastro"
            description="Passo 2: Se cadastrando em Sumply"
            secondaryLabel="Precisamos de detalhes"
          />
          <ProgressStep
            label="Integrações"
            description="Passo 2: Conectar dados"
            secondaryLabel="Precisamos de dados!"
          />
        </ProgressIndicator>
      </S.ProgressWrapper>

      <S.FormWrapper>
        {currentStep === 0 && (
          <CredentialsStep
            handleInputChange={handleInputChange}
            handleNextStep={handleNextStep}
          />
        )}

        {currentStep === 1 && (
          <UserInfoStep
            handleInputChange={handleInputChange}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            formData={formData}
          />
        )}

        {currentStep === 2 && (
          <IntegrationStep
            handlePreviousStep={handlePreviousStep}
            formData={formData}
          />
        )}
      </S.FormWrapper>
    </LayoutWithoutMenu>
  );
}
