import React, { useEffect, useState } from "react";
import Card from "../../components/Card/Card";

import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import * as S from "./dashboard.style";
import Table from "../../components/Table/Table";
import {
  BuildingInsights_3,
  Currency,
  DeliveryParcel,
} from "@carbon/icons-react";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Dropdown } from "@carbon/react";
import { getColorForPercentage } from "../../styles/helpers/colors";

import {
  getBusiness,
  getMetrics,
  getMetricsales,
  getMetricstock,
  getProjecteds,
  getStockDaysWithPercent,
  getStockValues,
} from "../../services/Dashboard.service";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);

export default function Dashboard() {
  const [defaultValues, setDefaultValues] = useState({});
  const temporadas = [
    { id: "current", text: "Atual - 01/01/2023 a 31/12/2023" },
  ];
  const categorias = [{ id: "all", text: "Todas" }];
  const canais = [{ id: "all", text: "Todos" }];

  useEffect(() => {
    const getDefaultInfos = async () => {
      try {
        const values = {
          business: await getBusiness(),
          metrics: await getMetrics(),
          metricsales: await getMetricsales(),
          metricstock: await getMetricstock(),
          projecteds: await getProjecteds(),
          stockDaysWithPercent: await getStockDaysWithPercent(),
          stockValues: await getStockValues(),
        };
        setDefaultValues(values);
      } catch (error) {
        const jsonError = JSON.parse(error.message);
        if (jsonError.status === 401) {
          window.location.href = "/";
        }
        console.log(jsonError);
      }
    };
    getDefaultInfos();
  }, []);

  return (
    <LayoutWithMenu isMenu isCenter={false}>
      <S.title border>
        <h3>Oportunidades</h3>
      </S.title>
      <S.section>
        <S.section className="filterWrapper">
          <div className="filters">
            <span className="sub">Temporadas</span>
            <Dropdown
              id="temporadas"
              type="inline"
              label="Temporadas"
              items={temporadas}
              itemToString={(item) => (item ? item.text : "")}
              selectedItem={temporadas[0]}
            />
            <span className="sub">Categorias</span>
            <Dropdown
              id="categorias"
              type="inline"
              label="Categorias"
              items={categorias}
              itemToString={(item) => (item ? item.text : "")}
              selectedItem={categorias[0]}
            />
            <span className="sub">Canais</span>
            <Dropdown
              id="canais"
              type="inline"
              label="Canais"
              items={canais}
              itemToString={(item) => (item ? item.text : "")}
              selectedItem={canais[0]}
            />
          </div>
        </S.section>
        <S.section center style={{ marginTop: "15px" }}>
          <Card
            icon={<BuildingInsights_3 size={24} color="#777A7E" />}
            title="Negócio"
            tag={defaultValues?.business?.classify.toLowerCase()}
            score={2}
            bigInfo={
              <span
                style={{
                  color: getColorForPercentage(
                    defaultValues?.business?.percentage
                  ),
                }}
              >
                {defaultValues?.business?.percentage}%
              </span>
            }
            parent
          />
        </S.section>
        <S.section center row style={{ alignItems: "flex-start" }}>
          <S.section center style={{ width: "50%", padding: "0 16px" }}>
            <Card
              icon={<Currency size={24} color="#777A7E" />}
              title="Vendas"
              tag={defaultValues?.metricsales?.classify.toLowerCase()}
              score={1}
              bigInfo={
                <span
                  style={{
                    color: getColorForPercentage(
                      defaultValues?.metricsales?.percentage
                    ),
                  }}
                >
                  {defaultValues?.metricsales?.percentage}%
                </span>
              }
              parent
              child
            />
            <S.section center row>
              <Card
                icon={<Currency size={24} color="#777A7E" />}
                subtitle="Fator"
                title="Performance"
                tag={defaultValues?.metrics?.cluster?.classify.toLowerCase()}
                score={1}
                bigInfo={
                  <span
                    style={{
                      color: getColorForPercentage(
                        defaultValues?.metrics?.cluster?.percentage
                      ),
                    }}
                  >
                    {defaultValues?.metrics?.cluster?.percentage}%
                  </span>
                }
                child
              />
              <Card
                icon={<DeliveryParcel size={24} color="#777A7E" />}
                subtitle="Fator"
                title="Velocidade"
                tag={defaultValues?.metrics?.sellThrough?.classify.toLowerCase()}
                score={1}
                bigInfo={
                  <span
                    style={{
                      color: getColorForPercentage(
                        defaultValues?.metrics?.sellThrough?.percentage
                      ),
                    }}
                  >
                    {defaultValues?.metrics?.sellThrough?.percentage}%
                  </span>
                }
                child
              />
            </S.section>
            <S.section row>
              <Card
                subtitle="Venda em Valor"
                title="Realizada x Projetada (R$)"
                content={
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "4fr 1fr",
                    }}
                  >
                    <ProgressBar
                      label={`${defaultValues?.projecteds?.projectedRealizedSale?.saleMade.toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )} (${
                        defaultValues?.projecteds?.projectedRealizedSale
                          ?.differencePercentage
                      }%)`}
                      completed={
                        defaultValues?.projecteds?.projectedRealizedSale
                          ?.differencePercentage
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "4px",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>Projetado</span>
                      <span>
                        {defaultValues?.projecteds?.projectedRealizedSale?.saleDesigned.toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                      </span>
                    </div>
                  </div>
                }
              />
            </S.section>
            <S.section row>
              <Card
                subtitle="Venda em Unidades"
                title="Realizada x Projetada"
                content={
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "4fr 1fr",
                    }}
                  >
                    <ProgressBar
                      label={`${defaultValues?.projecteds?.projectedRealizedSaleStock?.saleMadeStock.toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )} (${
                        defaultValues?.projecteds?.projectedRealizedSaleStock
                          ?.differencePercentage
                      }%)`}
                      completed={
                        defaultValues?.projecteds?.projectedRealizedSaleStock
                          ?.differencePercentage
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "4px",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>Projetado</span>
                      <span>
                        {defaultValues?.projecteds?.projectedRealizedSaleStock?.saleDesigned.toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                      </span>
                    </div>
                  </div>
                }
              />
            </S.section>
          </S.section>
          <S.section center style={{ width: "50%", padding: "0 16px" }}>
            <Card
              icon={<Currency size={24} color="#777A7E" />}
              title="Estoque"
              tag={defaultValues?.metricstock?.classify.toLowerCase()}
              score={4}
              bigInfo={
                <span
                  style={{
                    color: getColorForPercentage(
                      defaultValues?.metricstock?.percentage
                    ),
                  }}
                >
                  {defaultValues?.metricstock?.percentage}%
                </span>
              }
              parent
              child
            />
            <S.section center row>
              <Card
                icon={<Currency size={24} color="#777A7E" />}
                subtitle="Fator"
                title="Saúde"
                tag={defaultValues?.metrics?.giro?.classify.toLowerCase()}
                score={1}
                bigInfo={
                  <span
                    style={{
                      color: getColorForPercentage(
                        defaultValues?.metrics?.giro?.percentage
                      ),
                    }}
                  >
                    {defaultValues?.metrics?.giro?.percentage}%
                  </span>
                }
                child
              />
              <Card
                icon={<DeliveryParcel size={24} color="#777A7E" />}
                subtitle="Fator"
                title="Cobertura"
                tag={defaultValues?.metrics?.age?.classify.toLowerCase()}
                score={5}
                bigInfo={
                  <span
                    style={{
                      color: getColorForPercentage(
                        defaultValues?.metrics?.age?.percentage
                      ),
                    }}
                  >
                    {defaultValues?.metrics?.age?.percentage}%
                  </span>
                }
                child
              />
            </S.section>
            <S.section row>
              <Card
                subtitle="Estoque (Dias)"
                title="Cobertura"
                content={
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "4fr 1fr",
                    }}
                  >
                    <ProgressBar
                      label={defaultValues?.stockDaysWithPercent?.stockDays}
                      completed={
                        defaultValues?.stockDaysWithPercent?.stockDaysPercent
                      }
                    />
                  </div>
                }
              />
            </S.section>
            <S.section row style={{ gap: "16px" }}>
              <Card
                subtitle="Estoque"
                title="Valor"
                bigInfo={
                  <div style={{ marginLeft: "-8px", marginTop: "10px" }}>
                    {defaultValues?.stockValues?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </div>
                }
              />
            </S.section>
          </S.section>
        </S.section>
        <S.section>
          <Table metricas={defaultValues?.metrics} />
        </S.section>
      </S.section>
    </LayoutWithMenu>
  );
}
