import React, { useState } from "react";
import {
  Button,
  Link,
  ComposedModal,
  ModalHeader,
  ModalBody,
} from "@carbon/react";
import {
  Connect,
  DataConnected,
  ArrowLeft,
  ArrowRight,
} from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import constsLogos from "../../../consts/logoConsts";
import { createUser } from "../../../services/UsersService";
import { createCompany } from "../../../services/CompaniesService";

import * as S from "./IntegrationStep.style";

import RenderLogo from "../../../components/RenderLogo/RenderLogo";
import Label from "../../../components/Label/Label";
import ButtonDefault from "../../../components/ButtonDefault/ButtonDefault";

export default function IntegrationStep({ handlePreviousStep, formData }) {
  const navigate = useNavigate();

  const [sucess, setSucess] = useState(false);
  const [registerError, setRegisterError] = useState(false);

  const handleConectar = () => {
    setSucess(!sucess);
    navigate("/createPlan");
  };

  const handleConfirmation = async () => {
    const newUser = {
      ...formData,
      first: true,
    };

    const newCompany = {
      companyName: formData.companyName,
      sector: formData.sector,
      employeeNumber: formData.employeeNumber,
    };

    try {
      await createCompany(newCompany);
      await createUser(newUser);
    } catch (error) {
      console.log(error);
      setRegisterError(true);
    }
  };

  const handleSkipStep = () => {
    handleConfirmation();
  };

  return (
    <>
      <S.Article>
        <h3>Garanta um fluxo contínuo e automatizado de informações!</h3>
        <h4>
          Otimize processos internos, eliminando o retrabalho e reduzindo erros.
        </h4>
        <p className="firstParagraph">
          Com a integração, dados vitais como vendas, estoque e finanças são
          sincronizadas em tempo real, proporcionando uma visão holística do seu
          negócio.
        </p>
        <p>
          Tomada de decisões embasadas em informações atualizadas e precisas
          impulsiona a agilidade operacional, permitindo que sua equipe foque no
          crescimento do negócio, ao invés de se ocupar com tarefas manuais
          repetitivas.
        </p>
      </S.Article>
      <S.Session>
        {!sucess && (
          <div className="content">
            <div className="p">
              <Label text="Conecte-se ao seu ERP" />
            </div>
            <div>
              {constsLogos.map((item) => (
                <S.ListContainer key={item}>
                  {RenderLogo(item)}
                  <div className="listItemDirectionEnd">
                    <Button
                      size="md"
                      kind="ghost"
                      renderIcon={Connect}
                      iconDescription="Connect"
                      className="buttom"
                      onClick={handleConectar}
                    >
                      Conectar
                    </Button>
                  </div>
                </S.ListContainer>
              ))}
            </div>

            <S.ButtonSet>
              <S.Button kind="secondary" onClick={handlePreviousStep}>
                <ArrowLeft /> Voltar
              </S.Button>
              <S.Button
                primary
                renderIcon={() => <ArrowRight />}
                onClick={() => navigate("/createPlan")}
              >
                Finalizar
              </S.Button>
            </S.ButtonSet>

            <S.TextWithLink>
              <Label>
                O seu ERP não está na lista?
                <Link className="linkColor">Entre em contato</Link>
              </Label>
            </S.TextWithLink>
            <S.TextWithLink>
              <Label>
                Seguir sem conectar ERP?
                <Link className="linkColor" onClick={handleSkipStep}>
                  Pule esta etapa
                </Link>
              </Label>
            </S.TextWithLink>
          </div>
        )}
        {sucess && (
          <div className="content">
            <div className="p">
              <Label text="Conecte-se ao seu ERP" />
            </div>
            <div>
              <S.ListContainer>
                {RenderLogo("contaAzul")}
                <div className="listItemDirectionEnd">
                  <Button
                    size="md"
                    kind="ghost"
                    renderIcon={DataConnected}
                    iconDescription="DataConnected"
                    className="buttom"
                  >
                    Desconectar
                  </Button>
                </div>
              </S.ListContainer>
            </div>
            <S.TextWithLink>
              <Label>
                O seu ERP não está na lista?
                <Link className="linkColor">Entre em contato</Link>
              </Label>
            </S.TextWithLink>

            <S.ButtonSet>
              <S.Button kind="secondary" onClick={handlePreviousStep}>
                <ArrowLeft /> Voltar
              </S.Button>
              <S.Button
                primary
                renderIcon={() => <ArrowRight />}
                onClick={() => navigate("/createPlan")}
              >
                Finalizar
              </S.Button>
            </S.ButtonSet>
          </div>
        )}
      </S.Session>

      <ComposedModal
        onClose={() => setRegisterError(false)}
        open={registerError}
        size="sm"
      >
        <ModalHeader title="Erro ao cadastrar usuário" />
        <ModalBody>
          Ocorreu um erro no cadastro do usuário. Por favor, tente novamente ou
          contate a equipe Sumply.
        </ModalBody>
        <ButtonDefault kind="secondary" onClick={() => setRegisterError(false)}>
          Retornar
        </ButtonDefault>
      </ComposedModal>
    </>
  );
}
