import styled from "styled-components";

export const ContentLabelStyle = styled.div`
  color: var(--default-neutral-black, #000);

  /* Utility styles/label-02 */
  font-family: IBM Plex Sans;
  font-size: 14px;
  
`;
