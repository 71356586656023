import styled from "styled-components";
import { TabPanel as CTabPanel } from "@carbon/react";

export const Title = styled.article`
  gap: 24px;
  padding: 24px 0;

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #41a05f;
  }

  p {
    font-size: 14px;
    color: #adadad;
  }
`;

export const Section = styled.section``;

export const TabPanel = styled(CTabPanel)`
  background-color: #f4f5f5;
  text-align: center;
  h4 {
    font-size: 24px;
    font-weight: 500;
  }
`;
