import React, { useContext } from "react";
import {
  DataTable,
  TableContainer,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextInput,
} from "@carbon/react";

import * as S from "../LeadTimeStep.style";
import { CreatePlanContext } from "../../../../contexts/CreatePlanContext";

export default function SkuTable({
  getLeadTime,
  defineLeadTime,
  disableInputs,
}) {
  const { products } = useContext(CreatePlanContext);
  const headers = [
    { title: "SKU", key: "produto" },
    { title: "Tempo de entrega", key: "leadTime" },
  ];
  return (
    <DataTable rows={products} headers={headers}>
      {({ rows, headers, getHeaderProps, getTableProps }) => (
        <TableContainer>
          <S.Table {...getTableProps()} size="md">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader
                    id={header.title}
                    {...getHeaderProps({ header })}
                  >
                    {header.title}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIdx) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      {rows[rowIdx].cells[0].value || "Nome do Sku"}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "180px",
                        }}
                      >
                        <TextInput
                          placeholder="Tempo em dias"
                          type="text"
                          value={getLeadTime(row.id) || ""}
                          onChange={(e) =>
                            defineLeadTime(row.id, e.target.value)
                          }
                          inputMode="numeric"
                          pattern="[0-9]*"
                          disabled={disableInputs}
                        />{" "}
                        dias
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </S.Table>
        </TableContainer>
      )}
    </DataTable>
  );
}
