import { createContext, useState, useEffect } from "react";

import { getProducts } from "../services/Products.service";
import { getAllCategories } from "../services/ProductCategoriesService";
import { getInflation } from "../services/Inflation.service";

export const CreatePlanContext = createContext();

export default function CreatePlanProvider({ children }) {
  const [yearTarget, setYearTarget] = useState("");
  const [inflation, setInflation] = useState("");
  const [lastYearRevenue, setLastYearRevenue] = useState("");
  const [periodTarget, setPeriodTarget] = useState("");
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [channels, setChannels] = useState({
    fisica: 0,
    digital: 0,
    atacado: 0,
  });
  const [leadTime, setLeadTime] = useState([]);
  const [commercialDays, setCommercialDays] = useState([
    { day: 9, month: 3, name: "Páscoa" },
    { day: 15, month: 2, name: "Dia do Consumidor" },
    { day: 12, month: 5, name: "Dia dos Namorados" },
    { day: 14, month: 4, name: "Dia das Mães" },
    { day: 11, month: 7, name: "Dia dos Pais" },
    { day: 29, month: 10, name: "Black friday" },
  ]);
  const [personalizedDates, setPersonalizedDates] = useState([]);
  const [selectedCurveId, setSelectedCurveId] = useState(0);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const useCategories = categories.length;

  const today = new Date();
  const currentYear = today.getFullYear();

  const allDates = commercialDays.concat(personalizedDates);
  const selectedDates = allDates.filter((date) => date.selected);

  const curves = [
    { id: 0, name: "Sugerida" },
    { id: 1, name: "Venda digital" },
    { id: 2, name: "Invernais" },
    { id: 3, name: "Verão" },
    { id: 4, name: "Comercial" },
  ];

  const selectedCurve = curves.find(
    (curve) => curve.id === selectedCurveId
  )?.name;

  const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const writeMonthLabel = (date) => {
    const [month] = date.split("-");
    const monthIndex = parseInt(month, 10);
    const monthAbbreviation = monthAbbreviations[monthIndex];

    const yearSuffix = date.slice(-2);

    return `${monthAbbreviation}/${yearSuffix}`;
  };

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      const fetchedProducts = await getProducts(token);
      setProducts(fetchedProducts);

      const fetchedCategories = await getAllCategories(token);
      setCategories(fetchedCategories);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInflation = async () => {
    try {
      const fetchedInflation = await getInflation();
      setInflation(fetchedInflation);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchInflation();
  }, []);

  return (
    <CreatePlanContext.Provider
      value={{
        currentYear,
        inflation,
        setInflation,
        lastYearRevenue,
        setLastYearRevenue,
        yearTarget,
        setYearTarget,
        periodTarget,
        setPeriodTarget,
        selectedMonths,
        setSelectedMonths,
        channels,
        setChannels,
        leadTime,
        setLeadTime,
        useCategories,
        products,
        categories,
        setCategories,
        commercialDays,
        setCommercialDays,
        personalizedDates,
        setPersonalizedDates,
        selectedDates,
        selectedCurveId,
        setSelectedCurveId,
        selectedCurve,
        writeMonthLabel,
        monthAbbreviations,
      }}
    >
      {children}
    </CreatePlanContext.Provider>
  );
}
