import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Box = styled.div`
  background-color: #eaeaeb;
  border-top: 2px solid #dadbdd;
  padding: 16px;

  .header {
    display: flex;
    justify-content: space-between;

    .grid {
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 16px;
      width: fit-content;

      .cell {
        text-align: left;
        .t1 {
          font-size: 12px;
          font-weight: 400;
          color: #a7a9ac;
        }

        .t2 {
          font-size: 16px;
          font-weight: 500;
          color: black;
        }
      }
    }
  }

  ul.list {
    list-style-type: disc;
    list-style-position: inside;
    padding-top: 16px;

    li {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 8px;
    }
  }
`;

export const Message = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding: 16px;
  margin-top: 16px;

  ${({ type }) => {
    if (type === "success") {
      return css`
        background-color: #5bce7f;
      `;
    }

    if (type === "error") {
      return css`
        background-color: #fc7d84;
      `;
    }
  }}
`;
