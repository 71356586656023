import styled from "styled-components";

export const ProgressWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;

  .cds--progress {
    width: 100%;
    .cds--progress-step {
      inline-size: 100%;

      .cds--progress-line {
        inline-size: 100%;
      }
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: start;
`;
