import React, { useEffect, useState } from "react";
import { DatePicker, DatePickerInput, TextInput } from "@carbon/react";
import moment from "moment";
import "moment/locale/pt-br";

import * as S from "./CustomPeriodSelection.style";

export default function CustomPeriodSelection({ dates, setDates }) {
  const [errorMessage, setErrorMessage] = useState("");

  const formatDate = (date) => {
    if (!date) return "";
    const options = { month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("pt-BR", options);
  };

  const isStartValid = () => {
    const today = moment();
    const selectedDate = moment(dates.startDate);
    return selectedDate.isSameOrAfter(today, "month");
  };

  const isEndValid = () => {
    return dates.endDate ? isEndFuture() && isRangeMinimum() : true;
  };

  const isRangeMinimum = () => {
    const start = moment(dates.startDate);
    const maxEndDate = start.clone().add(18, "months");

    return dates.endDate
      ? moment(dates.endDate).isSameOrBefore(maxEndDate, "month")
      : true;
  };

  const isEndFuture = () => {
    const today = moment();
    const startDate = dates.startDate ? moment(dates.startDate) : today;
    const endDate = dates.endDate ? moment(dates.endDate) : null;

    if (!startDate || !endDate) return true;

    return endDate.isSameOrAfter(startDate, "month");
  };

  useEffect(() => {
    const validStart = isStartValid();
    const validEnd = isEndValid();

    let error = "";

    if (!validStart) {
      error = "O mês inicial deve ser o mês atual ou futuro.";
    } else if (!validEnd) {
      error = "O período deve ter no mínimo 1 e no máximo 18 meses.";
    }
    setErrorMessage(error);
  }, [dates]);

  return (
    <>
      <S.Inputs>
        <TextInput
          labelText="Nome"
          value={dates?.name}
          onChange={(e) => {
            setDates({
              ...dates,
              name: e.target.value,
            });
          }}
        />
        <DatePicker
          datePickerType="single"
          dateFormat="m/Y"
          value={formatDate(dates.startDate)}
          onChange={([date]) => {
            setDates({
              ...dates,
              startDate: date,
            });
          }}
        >
          <DatePickerInput
            placeholder="mm/aaaa"
            labelText="Início do Período (inclusive)"
            invalid={!isStartValid()}
            allowInput={false}
          />
        </DatePicker>
        <DatePicker
          datePickerType="single"
          dateFormat="m/Y"
          value={formatDate(dates.endDate)}
          onChange={([date]) => {
            setDates({
              ...dates,
              endDate: date,
            });
          }}
        >
          <DatePickerInput
            placeholder="mm/aaaa"
            labelText="Final do Período (inclusive)"
            invalid={!isEndValid()}
          />
        </DatePicker>
      </S.Inputs>
      <S.Text>{errorMessage}</S.Text>
    </>
  );
}
