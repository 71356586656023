import axios from "./BaseService";

const API_URL =
  `${process.env.REACT_APP_API_URL}` ||
  "http://localhost:3001";

export async function createUser(user) {
  const usersUrl = `${API_URL}/api/users`;
  const response = await axios.post(usersUrl, user);
  return response.data;
}
