export const globalStyles = {
  size: {
    sm: "15px",
    md: "25px",
    lg: "48px",
  },
  colors: {
    linkPrimary: "#05B33A",
    buttonPrimary: "#48FA7F"
  },
};