import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
`;

export const StockSection = styled.div`
  display: flex;
  gap: 20px;
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0.5rem;
`;

export const Number = styled.p`
  font-size: 17px;
`;

export const Box = styled.div`
  background-color: white;
  min-width: 170px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ twoRows }) => (twoRows ? "column" : "row")};
  gap: ${({ twoRows }) => (twoRows ? "5px" : "")};
`;

export const RightContainer = styled.div`
  display: flex;
  gap: 40px;
`;
