export default function Logo() {
  return (
    <svg
      width="118"
      height="24"
      viewBox="0 0 118 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1892 8.79571V3.04218L23.911 5.91894L18.1892 8.79571Z"
        fill="#27733F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9898 8.79565L12.2681 5.91888L17.9898 3.04211L17.9898 8.79565Z"
        fill="#27733F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1421 5.75469V0.00115967L17.8639 2.87793L12.1421 5.75469Z"
        fill="#27733F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9369 5.75462L6.17261 2.87733L11.9369 3.05176e-05L11.9369 5.75462Z"
        fill="#41A05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.04907 8.7957V3.04111L11.8134 5.9184L6.04907 8.7957Z"
        fill="#41A05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.84751 8.79571L0.125732 5.91894L5.84751 3.04218L5.84751 8.79571Z"
        fill="#41A05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1421 11.8357V6.08221L17.8639 8.95898L12.1421 11.8357Z"
        fill="#0D451E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9369 11.8359L6.17261 8.95863L11.9369 6.08133L11.9369 11.8359Z"
        fill="#0D451E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.04907 14.8777V9.12314L11.8134 12.0004L6.04907 14.8777Z"
        fill="#75FB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.84751 14.8766L0.125732 11.9998L5.84751 9.12305L5.84751 14.8766Z"
        fill="#75FB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1421 17.9175V12.164L17.8639 15.0408L12.1421 17.9175Z"
        fill="#5BCE7F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9369 17.9184L6.17261 15.0411L11.9369 12.1638L11.9369 17.9184Z"
        fill="#5BCE7F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1892 14.8765V9.12299L23.911 11.9998L18.1892 14.8765Z"
        fill="#5BCE7F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9898 14.8765L12.2681 11.9998L17.9898 9.12299L17.9898 14.8765Z"
        fill="#5BCE7F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72178 8.95858L0 11.8354V6.08182L5.72178 8.95858Z"
        fill="#75FB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.84751 20.9588L0.125732 18.082L5.84751 15.2053L5.84751 20.9588Z"
        fill="#75FB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8124 18.0825L6.0481 20.9598V15.2052L11.8124 18.0825Z"
        fill="#75FB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9369 23.9997L6.17261 21.1224L11.9369 18.2451V23.9997Z"
        fill="#75FB9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8639 21.1218L12.1421 23.9986V18.2451L17.8639 21.1218Z"
        fill="#41A05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9898 20.9586L12.2681 18.0818L17.9898 15.205L17.9898 20.9586Z"
        fill="#41A05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.911 18.0818L18.1892 20.9586V15.205L23.911 18.0818Z"
        fill="#41A05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.035 17.9175L18.3132 15.0408L24.035 12.164L24.035 17.9175Z"
        fill="#41A05F"
      />
      <path d="M99.6155 18.3822V0H102.875V18.3822H99.6155Z" fill="white" />
      <path
        d="M40.588 18.6885C39.4674 18.6885 38.4826 18.5097 37.6336 18.1523C36.7846 17.7779 36.1055 17.2672 35.5961 16.6205C35.0867 15.9737 34.7811 15.2248 34.6792 14.3737H37.9647C38.0666 14.8673 38.3382 15.2929 38.7797 15.6503C39.2381 15.9907 39.8239 16.1609 40.537 16.1609C41.2502 16.1609 41.768 16.0162 42.0907 15.7269C42.4302 15.4375 42.6 15.1056 42.6 14.7312C42.6 14.1865 42.3623 13.8206 41.8869 13.6333C41.4115 13.4291 40.7493 13.2334 39.9003 13.0461C39.357 12.927 38.8052 12.7823 38.2448 12.6121C37.6845 12.4419 37.1667 12.2291 36.6912 11.9738C36.2328 11.7015 35.8593 11.3611 35.5706 10.9526C35.282 10.5271 35.1376 10.008 35.1376 9.39523C35.1376 8.27187 35.5791 7.32723 36.462 6.5613C37.3619 5.79538 38.6184 5.41241 40.2314 5.41241C41.7256 5.41241 42.9141 5.76134 43.7971 6.45918C44.697 7.15702 45.2318 8.11868 45.4016 9.34417H42.3199C42.1331 8.40803 41.4285 7.93997 40.206 7.93997C39.5947 7.93997 39.1193 8.05911 38.7797 8.2974C38.4571 8.53569 38.2958 8.83355 38.2958 9.19098C38.2958 9.56543 38.542 9.86329 39.0344 10.0846C39.5268 10.3058 40.1805 10.5101 40.9955 10.6973C41.8784 10.9015 42.6849 11.1313 43.415 11.3866C44.1621 11.6249 44.7564 11.9909 45.1979 12.4845C45.6393 12.961 45.86 13.6504 45.86 14.5525C45.877 15.3354 45.6733 16.0418 45.2488 16.6715C44.8243 17.3013 44.2131 17.7949 43.415 18.1523C42.617 18.5097 41.6747 18.6885 40.588 18.6885Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.938 14.2867C55.8109 14.5363 55.6552 14.7614 55.471 14.9619C54.8937 15.6087 54.1551 15.9321 53.2552 15.9321C52.3044 15.9321 51.6082 15.6427 51.1668 15.064C50.7253 14.4853 50.5046 13.6513 50.5046 12.562V5.71973H47.27V12.8684C47.27 14.7747 47.6945 16.2214 48.5435 17.2086C49.4094 18.1958 50.6319 18.6894 52.211 18.6894C53.1958 18.6894 54.0532 18.4681 54.7833 18.0256C55.2328 17.7594 55.6177 17.4377 55.938 17.0607V14.2867ZM56.6238 18.383H59.6224V5.71973H56.6238V18.383Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.6943 5.71994V18.3832H64.8523V5.71994H61.6943ZM65.538 6.6265V9.42815C65.6015 9.32744 65.6701 9.23175 65.7439 9.14108C66.2872 8.4943 66.9919 8.17091 67.8578 8.17091C68.7407 8.17091 69.3944 8.46025 69.8189 9.03895C70.2434 9.61765 70.4556 10.4517 70.4556 11.541V18.3832H73.7157V11.8473C73.7157 10.6899 73.9788 9.78786 74.5052 9.14108C75.0485 8.4943 75.7617 8.17091 76.6446 8.17091C77.5105 8.17091 78.1472 8.46025 78.5548 9.03895C78.9792 9.61765 79.1915 10.4517 79.1915 11.541V18.3832H82.4515V11.2346C82.4515 9.3283 82.01 7.88155 81.1271 6.89436C80.2442 5.90717 78.9792 5.41357 77.3322 5.41357C76.4154 5.41357 75.5664 5.61782 74.7854 6.02631C74.0213 6.43481 73.41 6.98798 72.9516 7.68582C72.1366 6.17099 70.7528 5.41357 68.8002 5.41357C67.9172 5.41357 67.1362 5.6008 66.457 5.97525C66.1171 6.15871 65.8108 6.37579 65.538 6.6265Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.482 5.71994H84.2742V24H87.5342V16.6471C87.9756 17.2939 88.5529 17.796 89.2661 18.1535C89.9792 18.5109 90.8112 18.6896 91.762 18.6896C92.9506 18.6896 94.0118 18.4088 94.9456 17.8471C95.8795 17.2684 96.6181 16.4854 97.1614 15.4983C97.7048 14.494 97.9764 13.3537 97.9764 12.0771C97.9764 10.8006 97.7048 9.6602 97.1614 8.65599C96.6181 7.65178 95.8795 6.86032 94.9456 6.28162C94.0118 5.70292 92.9506 5.41357 91.762 5.41357C90.6923 5.41357 89.8179 5.62633 89.1387 6.05184C88.7771 6.28423 88.4534 6.53692 88.1677 6.80992V9.71862C88.2611 9.5883 88.3641 9.46386 88.4765 9.34532C89.1557 8.63046 90.0217 8.27303 91.0744 8.27303C92.1101 8.27303 92.9676 8.63046 93.6467 9.34532C94.3259 10.0602 94.6655 10.9708 94.6655 12.0771C94.6655 13.1835 94.3259 14.0855 93.6467 14.7834C92.9676 15.4812 92.1101 15.8302 91.0744 15.8302C90.0217 15.8302 89.1557 15.4812 88.4765 14.7834C87.8143 14.0685 87.4833 13.1579 87.4833 12.0516C87.4833 11.9174 87.4881 11.7862 87.4979 11.658H87.482V5.71994Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.443 17.5405L106.514 23.9998H109.978L112.264 18.7911L109.384 10.9561L107.303 5.71973H103.763L108.679 17.5405H109.443ZM111.173 13.8356L112.661 17.8851L118 5.71973H114.537L111.173 13.8356Z"
        fill="white"
      />
    </svg>
  );
}
