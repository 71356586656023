import styled from "styled-components";

export const ContentBtnGoogle = styled.div`
  margin-top: 50px;
  width: 350px;
`;

export const ContentPhaseOr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 15px;
  span {
    margin: 0 12px;
  }
  i {
    background-color: #a8a8a8;
    width: 88px;
    height: 2px;
  }
`;

export const Form = styled.form`
  width: 350px;
  gap: 25px;
  display: grid;
  .submitBtn {
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
  .cds--btn:disabled {
    background-color: var(--cds-button-disabled, #c6c6c6) !important;
  }
  .termsAndPolicy {
    justify-content: center;
    font-size: 12px;
    a {
      color: #05b33a !important;
      font-size: 12px;
      margin: 0 5px;
    }
  }
`;

export const LoginSection = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;

  .link {
    color: #05b33a;
  }
`;
