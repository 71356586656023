import axios from "./BaseService";

const API_URL =
  `${process.env.REACT_APP_API_URL}` ||
  "http://localhost:3001";

export async function getAllCategories(token) {
  const url = `${API_URL}/api/categoryprodutcs`;
  const headers = { authorization: token };
  const response = await axios.get(url, { headers });
  return response.data;
}

export async function updateCategories(token, newCategories) {
  const url = `${API_URL}/api/categoryprodutcs`;
  const headers = { authorization: token };
  const response = await axios.patch(url, newCategories, { headers });
  return response.data;
}
