import axios from "./BaseService";

const API_URL =
  `${process.env.REACT_APP_API_URL}` ||
  "http://localhost:3001";

export async function savePlan(token, plan) {
  const planUrl = `${API_URL}/api/planning`;
  const headers = { authorization: token };
  const response = await axios.post(planUrl, plan, { headers });
  return response.data;
}
