import { ArrowDown, CheckmarkFilled } from "@carbon/icons-react";
import * as S from "./GeneralView.style";
import { faker } from "@faker-js/faker/locale/af_ZA";
import ItemView from "./ItemView/ItemView";
import IndexesSection from "./IndexesSection/IndexesSection";

const mockAmount = () => faker.number.int(2500);

export default function GeneralView({ onNavTab }) {
  const overItems = [
    { category: "Polo", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
  ];
  const lackItems = [
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
  ];
  const overTotalItems = overItems.reduce((acc, { amount }) => acc + amount, 0);
  const lackTotalItems = lackItems.reduce((acc, { amount }) => acc + amount, 0);
  const activeRecommendations = faker.number.int(300);
  const totalItems = overTotalItems + lackTotalItems;

  const itemsByCategory = [
    {
      nome: "Polo",
      estoque: {
        quantidade: -6525,
        categoria: "Estoque em falta",
      },
      acoes: [
        "Transferir 4.502 peças de Abril. Sugestão 2.500 peças para Maio; 2.000 para Junho",
        "Incluir compra de 700 peças em Junho",
        "Incluir compra de 2.750 peças em Julho",
        "Incluir compra de 2.100 peças em Agosto",
        "Incluir compra de 1.250 peças em Outubro",
      ],
      ROI: "R$ 741.841,00",
      status: [
        {
          titulo: "Análise das Ações",
          completa: false,
        },
        {
          titulo: "Ajuste do Plano",
          completa: false,
        },
        {
          titulo: "Alinhamento dos Ajustes",
          completa: false,
        },
      ],
    },
    {
      nome: "Acessórios",
      estoque: {
        quantidade: 7147,
        categoria: "Estoque em excesso",
      },
      acoes: [
        "Transferir 4.502 peças de Abril. Sugestão 2.500 peças para Maio; 2.000 para Junho",
        "Incluir compra de 700 peças em Junho",
        "Incluir compra de 2.750 peças em Julho",
      ],
      ROI: "R$ 102.746,00",
      status: [
        {
          titulo: "Análise das Ações",
          completa: true,
        },
        {
          titulo: "Ajuste do Plano",
          completa: false,
        },
        {
          titulo: "Alinhamento dos Ajustes",
          completa: false,
        },
      ],
    },
  ];
  return (
    <>
      <IndexesSection />
      <S.Wrapper>
        <div className="lista">
          {itemsByCategory.map((item) => (
            <ItemView item={item} />
          ))}
        </div>
      </S.Wrapper>
    </>
  );
}
