import styled from "styled-components";
import { globalStyles } from "../../consts/globalStyles";

const content = styled.div`
  .cds--modal-container {
    inline-size: 60%;
    overflow: auto;
    background-color: #f4f5f5;
    border-radius: 3px;
  }
  .green {
    color: #05b33a;
  }
`;

const headerTitle = styled.div`
  .id {
    color: var(--text-text-helper, #6f6f6f);

    font-family: IBM Plex Mono;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  h3 {
    color: var(--text-text-primary, #161616);

    font-size: 32px;
    font-style: normal;
    font-weight: 600;
  }
  .legend {
    color: var(--text-text-primary, #161616);

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
  }
`;

const contentModal = styled.div`
  padding: ${globalStyles.size.sm};
  display: grid;
  grid-template-rows: auto 1fr;
  .m-b-5 {
    margin-bottom: 10px;
  }
`;

const contentInputsValues = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
`;

const frameInfoAndValue = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 3px;
  background-color: #fff;
  min-height: 52px;
  font-size: 12px;

  color: #525252;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.32px;
  strong {
    color: #525252;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
  }
`;

const contentDialog = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 200px) 1fr;
  gap: 8px;
`;

const dialog = styled.div`
  border-radius: 3px;
  display: grid;
  gap: 16px;
  background-color: #fff;
  padding: ${globalStyles.size.sm};
  align-items: center;
  margin-bottom: 8px;
`;

const dialogTitle = styled.div`
  strong {
    display: block;
    font-size: 15px;
    margin-bottom: 8px;
  }
  .disabled {
    color: #c6c6c6;
  }
  .icons {
    display: flex;
    flex-direction: row;
    gap: 4px;
    > svg {
      fill: #41a05f;
    }
  }
  .text-status-icon {
    align-self: center;
    justify-self: center;
    margin-right: 8px;
    font-size: 15px;
  }
`;

const dialogText = styled.div`
  color: var(--text-text-secondary, #525252);

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.32px;
`;

const dialogOnlyText = styled.div`
  border-radius: 3px;
  display: grid;
  gap: 16px;
  background-color: #fff;
  padding: ${globalStyles.size.sm};
  align-items: center;
  margin-bottom: 8px;
`;

export {
  content,
  contentModal,
  contentInputsValues,
  frameInfoAndValue,
  contentDialog,
  dialogOnlyText,
  dialog,
  dialogText,
  dialogTitle,
  headerTitle,
};
