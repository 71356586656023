import styled from "styled-components";

const wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 24px 0 0;
  width: 100%;
`;

const wrapperParent = styled.div`
  display: inline-flex;
  position: relative;
  margin: 24px 0 0;
  justify-content: center;
  width: 50%;
  padding-bottom: 20px;
  border-bottom: 4px solid white;
  &::before {
    width: 4px;
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 20px;
    background-color: white;
    content: "";
    transform: translate(-50%, 0);
  }
  > div > div {
    min-width: 160px;
  }
`;

const wrapperParentChild = styled.div`
  display: inline-flex;
  position: relative;
  margin: -4px 0 0;
  justify-content: center;
  width: 50%;
  padding-bottom: 20px;
  border-bottom: 4px solid white;
  padding-top: 20px;
  &::before {
    width: 4px;
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 20px;
    background-color: white;
    content: "";
    transform: translate(-50%, 0);
  }
  &::after {
    width: 4px;
    position: absolute;
    left: 50%;
    top: 0;
    height: 20px;
    background-color: white;
    content: "";
    transform: translate(-50%, 0);
  }

  > div > div {
    min-width: 160px;
  }
`;

const wrapperChild = styled.div`
  display: inline-flex;
  position: relative;
  margin: -4px 2px 0 2px;
  justify-content: center;
  width: 50%;
  padding-top: 20px;
  &::after {
    width: 4px;
    position: absolute;
    left: 50%;
    top: 0;
    height: 20px;
    background-color: white;
    content: "";
    transform: translate(-50%, 0);
  }
`;

const card = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 16px;
  width: min-content;
  background: var(--layer-layer-02, #fff);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
`;

const cardSmall = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px;
  width: 100%;
  background: var(--layer-layer-02, #fff);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  > .info {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 8px;
  }
`;

const icon = styled.span`
  margin-right: 8px;
`;

const title = styled.div`
  color: var(--text-text-secondary, var(--text-text-secondary, #525252));

  font-size: 16px;
  font-weight: 600;
  width: 100%;
`;

const subtitle = styled.div`
  color: var(--text-text-secondary, var(--text-text-secondary, #525252));

  font-size: 12px;
  font-weight: 400;
`;

const range = styled.span`
  display: flex;
  margin-right: 8px;
`;

const tag = styled.span`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  background-color: #000000;
  color: white;
  padding: 4px 8px;
  border-radius: 100px;
  align-items: center;
`;

const bigInfo = styled.span`
  display: flex;
  font-size: 24px;
  font-weight: 500;
  margin-left: 8px;
`;

export {
  wrapper,
  wrapperParent,
  wrapperParentChild,
  wrapperChild,
  card,
  cardSmall,
  icon,
  title,
  subtitle,
  tag,
  range,
  bigInfo,
};
