import styled from "styled-components";

export const Inputs = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 24px 0;
  margin: 10px;

  input {
    background-color: white;
    border-block-end: none;
  }

  .cds--form-item {
    flex: unset;
  }

  .cds--date-picker {
    gap: 20px;
  }
`;

export const Text = styled.div`
  color: red;
  font-size: 14px;
`;
