import styled from "styled-components";
import { globalStyles } from "../../consts/globalStyles";

const titleCommon = `
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  font-size: 24px;
`;

const section = styled.section`
  display: flex;
  width: 100%;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  align-items: ${({ center }) => (center ? "center" : "start")};
  justify-content: space-around;
  &.filterWrapper {
    background-color: #f4f5f5;
    padding: 16px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    > .filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      > span.sub {
        font-size: 10px;
        margin-right: 8px;
      }
      .cds--dropdown {
        background-color: white;
      }
    }
  }
`;

const title = styled.article`
  ${titleCommon}
  align-items: start;
  background-color: #ffffff;
`;

const score = styled.div`
  display: flex;
`;

const article = styled.article`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;
`;

const contentHeader = styled.div`
  ${titleCommon}
`;

const gridCardsSales = styled.div`
  display: grid;
  padding: ${globalStyles.size.sm};
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;
const gridCardsStock = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  padding: ${globalStyles.size.sm};
  .subGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`;
const tabs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
`;
const tab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const itemTab = styled.button`
  cursor: pointer;
  overflow: hidden;
  color: var(
    --tag-green-text,
    var(--tag-green-tag-color-green, ${({ color }) => color})
  );
  text-align: center;
  padding: 0;
  width: 100%;
  border: none;

  background-color: ${({ active }) => (active ? "#f4f4f4" : "#E0E0E0")};
  border-top: ${({ active }) => (active ? "1px solid #48fa7f" : "none")};
  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    /* Fixed heading styles/heading-compact-02 */
    font-family: IBM Plex Sans;
    font-size: 18px;
    font-style: normal;
    padding: none;
    padding: ${globalStyles.size.sm};

    border-left: ${({ border }) => (border ? "1px solid #c6c6c6" : "none")};
    border-right: ${({ border }) => (border ? "1px solid #c6c6c6" : "none")};
  }
  .line {
    background-color: ${({ active }) => (active ? "" : "rgb(232, 232, 232)")};
    font-weight: 600;
    padding: ${globalStyles.size.sm};
    justify-content: center;
    display: flex;
    .bagde {
      margin-left: 5px;
    }
    .disabled {
      color: #c6c6c6;
    }
  }
`;

export {
  section,
  title,
  score,
  article,
  contentHeader,
  gridCardsSales,
  gridCardsStock,
  tabs,
  tab,
  itemTab,
};
