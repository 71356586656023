import React, { useState } from "react";
import { Column, Tabs, Tab, TabList, TabPanels } from "@carbon/react";
import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import GeneralView from "./GeneralView/GeneralView";
import FinalResults from "./FinalResults/FinalResults";
import BusinessView from "./BusinessView/BusinessView";

import * as S from "./Otb.style";

export default function Otb() {
  const [index, setIndex] = useState(0);
  return (
    <LayoutWithMenu isCenter={false}>
      <Column lg={16} md={8} sm={4}>
        <S.Section>
          <S.Title>
            <h3>Criar Planejamento</h3>
            <p>01/01/2024 - 31/12/2024</p>
          </S.Title>

          <Tabs
            selectedIndex={index}
            onChange={({ selectedIndex }) => setIndex(selectedIndex)}
          >
            <TabList fullWidth contained>
              <Tab>Visão geral do Planejamento</Tab>
              <Tab>Visão de Negócio</Tab>
              <Tab>Resultado Geral</Tab>
            </TabList>
            <TabPanels>
              <S.TabPanel>
                <GeneralView onNavTab={() => setIndex(1)} />
              </S.TabPanel>
              <S.TabPanel>
                <BusinessView onSavePlan={() => setIndex(2)} />
              </S.TabPanel>
              <S.TabPanel>
                <FinalResults />
              </S.TabPanel>
            </TabPanels>
          </Tabs>
        </S.Section>
      </Column>
    </LayoutWithMenu>
  );
}
