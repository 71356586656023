import React from "react";
import GoogleIcon from "../GoogleIcon/GoogleIcons";
import ButtonDefault from "../ButtonDefault/ButtonDefault";

import { ContentGoogleBtnStyle } from "./buttonGoogle.style";

export default function ButtonGoogle({ label }) {
  return (
    <ContentGoogleBtnStyle>
      <ButtonDefault
        kind="secondary"
        renderIcon={(props) => <GoogleIcon {...props} />}
        iconDescription="Google Icon"
      >
        {label}
      </ButtonDefault>
    </ContentGoogleBtnStyle>
  );
}
