import React from "react";
import PropTypes from "prop-types";
import { ComposedModal, ModalHeader } from "@carbon/react";
import {
  Flash,
  HealthCross,
  Box,
  Star,
  StarFilled,
  FlashFilled,
} from "@carbon/icons-react";

import * as S from "./modalDetalhe.style";
import { BoxFilled, HealthCrossFilled } from "../LocalIcons";

export default function ModalDetalhe({ open, item, setShow }) {
  const accordingStatus = (status, Icons, FilledIcons) => {
    function capitalizeFirstLetter(word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    switch (status) {
      case "excelente": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
          </div>
        );
      }
      case "ótimo": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <Icons />
          </div>
        );
      }
      case "regular": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
            <FilledIcons />
            <FilledIcons />
            <Icons />
            <Icons />
          </div>
        );
      }
      case "crítico": {
        return (
          <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
            <FilledIcons />
            <Icons />
            <Icons />
            <Icons />
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  if (!item)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>Ops</h2>
      </div>
    );
  const {
    sku,
    cor,
    produto,
    cluster,
    st,
    giro,
    idade,
    receita,
    shareReceita,
    crescReceita,
    precoMedioVenda,
    shareValorVenda,
    estoquePcs,
    estoqueRs,
    cobertura,
    feedbackMessage,
  } = item;

  return (
    <S.content>
      <ComposedModal open={open} onClose={() => setShow(false)}>
        <ModalHeader>
          <S.headerTitle>
            <span className="id">{sku}</span>
            <h3>{produto}</h3>
            <span className="legend">{cor}</span>
          </S.headerTitle>
        </ModalHeader>
        <S.contentModal>
          <h5 className="m-b-5">Informaçoes Gerais</h5>
          <S.contentInputsValues>
            <S.frameInfoAndValue>
              <span>Receita (RS)</span>
              <strong>{receita.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}</strong>
            </S.frameInfoAndValue>
            <S.frameInfoAndValue>
              <span>Receita (Share)</span>
              <strong>{shareReceita}</strong>
            </S.frameInfoAndValue>
            <S.frameInfoAndValue>
              <span>Cresc. Receita vs Último Ano</span>
              <strong>{crescReceita || 0}</strong>
            </S.frameInfoAndValue>
            <S.frameInfoAndValue>
              <span>Preço Médio de Venda (R$)</span>
              <strong>{precoMedioVenda.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>Share Valor de Venda (%)</span>
              <strong>{shareValorVenda}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>Estoque (Pçs)</span>
              <strong>{estoquePcs}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>Estoque (R$)</span>
              <strong>{estoqueRs.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>Cobertura (Dias)</span>
              <strong>{cobertura || 0}</strong>
            </S.frameInfoAndValue>
          </S.contentInputsValues>
        </S.contentModal>
        <S.contentModal>
          <h5 className="m-b-5">Fatores Sumply</h5>
          <div>
            <S.contentDialog>
              <div>
              <S.dialog key={1}>
                  <S.dialogTitle>
                    <strong className="title">Performance de Venda</strong>
                    <div>
                      {accordingStatus(cluster.toLowerCase(), Star, StarFilled)}
                    </div>
                  </S.dialogTitle>
                </S.dialog>

                <S.dialog key={2}>
                  <S.dialogTitle>
                    <strong className="title">Velocidade de Venda</strong>
                    <div>
                      {accordingStatus(st.toLowerCase(), Flash, FlashFilled)}
                    </div>
                  </S.dialogTitle>
                </S.dialog>
              </div>
              <div>

              <S.dialog key={3}>
                  <S.dialogTitle>
                    <strong className="title">Saúde do Estoque</strong>
                    <div>
                      {accordingStatus(
                        giro.toLowerCase(),
                        HealthCross,
                        HealthCrossFilled
                      )}
                    </div>
                  </S.dialogTitle>
                </S.dialog>

                <S.dialog key={4}>
                  <S.dialogTitle>
                    <strong className="title">Cobertura do Estoque</strong>
                    <div>
                      {accordingStatus(idade.toLowerCase(), Box, BoxFilled)}
                    </div>
                  </S.dialogTitle>
                </S.dialog>
              </div>
               

                <S.dialogOnlyText key={5}>
                <S.dialogText>
                  {feedbackMessage || "Sem Mensagem"}
                </S.dialogText>
              </S.dialogOnlyText>


             
              
            </S.contentDialog>
          </div>
        </S.contentModal>
      </ComposedModal>
    </S.content>
  );
}

ModalDetalhe.propTypes = {
  item: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
