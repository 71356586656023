import styled from "styled-components";

const Main = styled.main`
  padding-top: 3rem;
  height: 100vh;
  max-height: 100%;
  display: grid;
  grid-template-rows: ${({ isMenu }) => (isMenu ? "auto 1fr" : "auto")};
  align-items: ${({ isCenter }) => (isCenter ? "center" : "normal")};
`;
const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

export { Main, Section };
