import React, { useState } from "react";
import { faker } from "@faker-js/faker/locale/pt_BR";
import { NumericFormat } from "react-number-format";
import { Calculator, Save, Undo } from "@carbon/icons-react";
import { Toggle } from "@carbon/react";

import * as S from "./PlanModal.style";

export default function PlanModal({ onClose }) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const mockSells = Array.from({ length: 12 }, () => ({
    unit: { value: faker.number.int(100), isChange: false },
    price: { value: faker.number.int(1000), isChange: false },
  }));
  const mockExpectStock = Array.from(
    { length: 12 },
    () => -faker.number.int(3000)
  );
  const mockCarteira = Array.from(
    { length: 12 },
    () => -faker.number.int(1000)
  );
  const mockReceived = Array.from({ length: 12 }, () => ({
    value: -faker.number.int(1000),
    isChange: false,
  }));
  const mockCoverage = Array.from({ length: 12 }, () => faker.number.int(3000));
  const mockTurn = Array.from({ length: 12 }, () => ({
    value: faker.number.float({ min: 0, max: 2 }),
    isChange: false,
  }));
  const diff = [
    null,
    null,
    faker.number.int(1000),
    null,
    null,
    null,
    -faker.number.int(1000),
    null,
    null,
    faker.number.int(1000),
    null,
    null,
  ];

  const [sells, setSells] = useState(mockSells);
  const [received, setReceived] = useState(mockReceived);
  const [turn, setTurn] = useState(mockTurn);
  const [disabled, setDisabled] = useState(true);

  const [expectStock, setExpectStock] = useState(mockExpectStock);
  const [carteira, setCarteira] = useState(mockCarteira);
  const [coverage, setCoverage] = useState(mockCoverage);

  return (
    <>
      <S.ToggleBox>
        <Toggle
          labelA="Comparar ano passado"
          labelB="Comparar ano passado"
          defaultToggled
          className="toggle"
          size="sm"
        />
      </S.ToggleBox>
      <S.Table>
        <thead>
          <tr>
            <th />
            {months.map((m) => (
              <th>{m}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Estoque ideal para a venda</th>
            {expectStock.map((o) => (
              <td>{o}</td>
            ))}
          </tr>
          <tr>
            <th>Excesso/Falta de estoque</th>
            {diff.map((o) => (
              <td>
                {o < 0 && (
                  <S.Tag>
                    <div>Falta</div> {o}
                  </S.Tag>
                )}
                {o > 0 && (
                  <S.Tag isOver>
                    <div>Excesso</div> {o}
                  </S.Tag>
                )}
              </td>
            ))}
          </tr>

          <tr className="start-group">
            <th>Meta de venda</th>
            {mockSells.map(({ unit, price }) => (
              <td>
                {(unit.value * price.value).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            ))}
          </tr>
          <tr className="white">
            <th>Venda em peças</th>
            {mockSells.map(({ unit }) => (
              <td>
                <NumericFormat
                  type="number"
                  value={unit.value}
                  decimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                  displayType="text"
                />
              </td>
            ))}
          </tr>
          <tr className="white">
            <th>Preço médio</th>
            {mockSells.map(({ price }) => (
              <td>
                <NumericFormat
                  value={price.value}
                  prefix="R$ "
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  displayType="text"
                />
              </td>
            ))}
          </tr>

          <tr className="start-group">
            <th>Carteira</th>
            {carteira.map((value) => (
              <td>{value}</td>
            ))}
          </tr>
          <tr className="white">
            <th>Projeção de recebimento</th>
            {mockReceived.map(({ value }) => (
              <td>
                <NumericFormat
                  type="number"
                  value={value}
                  decimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                  displayType="text"
                />
              </td>
            ))}
          </tr>
          <tr>
            <th>Estoque final do mês</th>
            {mockReceived.map(({ value }, i) => (
              <td>
                <NumericFormat
                  value={value + carteira[i]}
                  displayType="text"
                  decimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                />
              </td>
            ))}
          </tr>

          <tr className="start-group">
            <th>Cobertura</th>
            {coverage.map((value) => (
              <td>{value}</td>
            ))}
          </tr>
          <tr className="white">
            <th>Giro</th>
            {mockTurn.map(({ value }) => (
              <td>
                <NumericFormat
                  type="number"
                  value={value}
                  decimalScale={1}
                  displayType="text"
                />
              </td>
            ))}
          </tr>
        </tbody>
      </S.Table>
      <S.ButtonGroup>
        <S.Button renderIcon={Undo} onClick={() => onClose(false)}>
          Voltar
        </S.Button>
        <S.Button green renderIcon={Calculator} onClick={() => {}}>
          Calcular
        </S.Button>
        <S.Button green renderIcon={Save} onClick={() => {}}>
          Ajustar categoria
        </S.Button>
      </S.ButtonGroup>
    </>
  );
}
