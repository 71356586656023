import React, { useState } from "react";
import { Toggle } from "@carbon/react";
import { CheckmarkFilled, CloseFilled } from "@carbon/icons-react";
import { faker } from "@faker-js/faker/locale/pt_BR";

import * as S from "./BusinessView.style";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
const overstock = [
  null,
  faker.number.int(2000),
  null,
  null,
  faker.number.int(2000),
  null,
  null,
  null,
  null,
  faker.number.int(2000),
  null,
  null,
];
const lackstock = [
  null,
  -faker.number.int(2000),
  null,
  null,
  -faker.number.int(2000),
  null,
  null,
  null,
  null,
  -faker.number.int(2000),
  null,
  null,
];
const sells = Array.from({ length: 12 }, () => -faker.number.int(1000));
const coverage = Array.from({ length: 12 }, () => -faker.number.int(1000));

const textSuggested = () =>
  faker.helpers
    .arrayElements([
      `Incluir compra de ${faker.number.int(
        999
      )} peças em ${faker.date.month()}.`,
      `Transferir ${faker.number.int(999)} peças de ${faker.date.month()}.`,
      `Sugestão ${faker.number.int(999)} peças para ${faker.date.month()}.`,
    ])
    .join(" ");

const slides = [
  {
    title: "Categoria Polo",
    suggested: [
      {
        text: "Transferir 4.502 peças de Abril. Sugestão 2.500 peças para Maio; 2.000 para Junho.",
      },
      { text: "Incluir compra de 700 peças em Junho." },
      { text: "Incluir compra de 2.750 peças em Julho." },
      { text: "Incluir compra de 2.100 peças em Agosto." },
      { text: "Incluir compra de 1.250 peças em Outubro." },
    ],
  },
  {
    title: "Categoria Acessórios",
    suggested: [
      { text: textSuggested() },
      { text: textSuggested() },
      { text: textSuggested() },
    ],
  },
  {
    title: "Categoria Camisas",
    suggested: [
      { text: textSuggested() },
      { text: textSuggested() },
      { text: textSuggested() },
    ],
  },
];

const Link = ({ text, hasConfirm, onSim }) => (
  <S.Link>
    {hasConfirm ? (
      <div>
        <button>
          <CheckmarkFilled size={24} className="confirm" />
        </button>
        <button>
          <CloseFilled size={24} className="reject" />
        </button>
      </div>
    ) : (
      <button className="simulate" onClick={onSim}>
        Simular
      </button>
    )}
    <span>{text}</span>
  </S.Link>
);

export default function BusinessView({ onSavePlan }) {
  const [slide, setSlide] = useState(0);
  const [open, setOpen] = useState(false);
  const [editCategory, setEditCategory] = useState(false);

  return (
    <S.Container>
      <h1>Saúde de estoque resumido - Visão empresa</h1>
      <Toggle
        labelA="Comparar ano passado"
        labelB="Comparar ano passado"
        defaultToggled
        className="toggle"
        size="sm"
      />
      <S.Table>
        <thead>
          <tr>
            <th />
            {months.map((m) => (
              <th>{m}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Excesso</th>
            {overstock.map((o) => (
              <td>
                {o ? (
                  <S.Tag isOver>
                    <div>Excesso</div> {o}
                  </S.Tag>
                ) : (
                  ""
                )}
              </td>
            ))}
          </tr>
          <tr>
            <th>Falta</th>
            {lackstock.map((o) => (
              <td>
                {o ? (
                  <S.Tag>
                    <div>Falta</div> {o}
                  </S.Tag>
                ) : (
                  ""
                )}
              </td>
            ))}
          </tr>
          <tr>
            <th>Venda</th>
            {sells.map((sell) => (
              <td>{sell}</td>
            ))}
          </tr>
          <tr>
            <th>Cobertura</th>
            {coverage.map((c) => (
              <td>{c}</td>
            ))}
          </tr>
        </tbody>
      </S.Table>
      {/* <EditPlan onSavePlan={onSavePlan} /> */}
    </S.Container>
  );
}
