import styled from "styled-components";
const MainStyles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;
const ContainerStyles = styled.div`
  height: 28px;
  width: 100%;
  background-color: #f4f5f5;
  border-radius: 2px;
`;

const FillerStyles = styled.div`
  height: 100%;
  width: ${({ width }) => (width ? width + "%" : "0%")};
  background-color: #41a05f;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const LabelStyles = styled.span`
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  color: #eeeeee;
`;

const SpanStyles = styled.span`
  //styleName: Utility styles/label-01;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  color: #f4f5f5;
`;

export { MainStyles, ContainerStyles, FillerStyles, LabelStyles, SpanStyles };
