import React, { useState } from "react";
import { TextInput, Link } from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import LayoutWithoutMenu from "../../layouts/LayoutWithoutMenu/LayoutWithoutMenu";
import ButtonDefault from "../../components/ButtonDefault/ButtonDefault";

import * as S from "./forgotPassword.style";

const TextInputProps = {
  type: "email",
  id: "TextEmail",
  placeholder: "E-mail",
  labelText: "",
  invalidText: "E-mail invalido",
  required: true,
};

export default function ForgotPassword() {
  const [showStatus, setShowStatus] = useState(false);
  const [textEmail, setTextEmail] = useState("");
  const [statusEmail, setStatusEmail] = useState(false);

  const navigate = useNavigate();

  const handleShowStatus = () => {
    if (textEmail && /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/.test(textEmail)) {
      setShowStatus(!showStatus);
    }
    setStatusEmail(true);
  };

  return (
    <LayoutWithoutMenu>
      {!showStatus && (
        <S.session>
          <S.sessionTitle>
            <h3>Recuperação de Senha</h3>
            <h4>Digite o e-mail utilizado para entrar no Sumply</h4>
          </S.sessionTitle>
          <TextInput
            onChange={(e) => {
              setTextEmail(e.target.value);
            }}
            invalid={statusEmail}
            {...TextInputProps}
          />
          <ButtonDefault
            type="button"
            className="submitBtn"
            renderIcon={() => <ChevronRight />}
            onClick={handleShowStatus}
          >
            Continuar
          </ButtonDefault>
          <div className="linkCameBack">
            <Link href="/">Voltar para o Login</Link>
          </div>
        </S.session>
      )}
      {showStatus && (
        <S.session>
          <S.sessionTitle>
            <h3>Recuperação de Senha</h3>
            <h4>Enviamos para o seu e-mail um link para resetar sua senha.</h4>
          </S.sessionTitle>
          <ButtonDefault
            type="button"
            className="submitBtn"
            renderIcon={() => <ChevronRight />}
            onClick={() => {
              navigate("/");
            }}
          >
            Ir para o login
          </ButtonDefault>
        </S.session>
      )}
    </LayoutWithoutMenu>
  );
}
