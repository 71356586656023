const API_URL = `${process.env.REACT_APP_API_URL}`;

export const startConfig = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch(`${API_URL}/start/config`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return true;
  }

  throw new Error(
    JSON.stringify({
      error: "Usuário não autenticado",
      status: 401,
    })
  );
};
