import styled, { css } from "styled-components";
import { Button as CButton } from "@carbon/react";

export const Form = styled.form`
  width: 350px;
  gap: 25px;
  display: grid;
  margin-top: 50px;
  .submitBtn {
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
  .termsAndPolicy {
    justify-content: center;
    font-size: 12px;
    a {
      color: #05b33a !important;
      font-size: 12px;
      margin: 0 5px;
    }
  }
`;

export const ButtonSet = styled.div`
  gap: 10px;
  padding: 24px 0;
  display: flex;
`;

export const Button = styled(CButton)`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-inline-end: 10px;
  max-inline-size: 100%;

  .icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${(props) => {
    if (props.primary) {
      return css`
        background-color: #75fb9f;
        color: #000;
      `;
    }

    if (props.kind === "tertiary") {
      return css`
        color: #000;
        border-color: #000;
      `;
    }

    if (props.kind === "ghost") {
      return css`
        color: #636569;
      `;
    }
  }}
`;
