import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 82rem) {
    .cds--modal-container--lg {
      inline-size: 96%;
    }
  }
`;

export const Section = styled.div`
  border-right: solid #bdbaba 1px;
`;

export const Title = styled.div`
  margin-left: 20px;
`;

export const CategoryTitle = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  color: #5d5d5d;
`;

export const ItemTitle = styled.div`
  font-weight: bold;
  font-size: 25px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 10px 0;
`;

export const ListItem = styled.li`
  margin: 15px 0;
`;
