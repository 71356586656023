import styled from "styled-components";
import { globalStyles } from "../../consts/globalStyles";
const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${globalStyles.size.md};
  border: none;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  img {
    width: auto;
    height: 48px;
  }
`;

const Main = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: start;
`;

const Footer = styled.div`
  display: flex;
  width: calc(100% + ${globalStyles.size.md});
  margin-bottom: -25px;
  align-items: end;
  flex-direction: column-reverse;
`;

export { Container, Header, Main, Footer };
